import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'preview-tab-application',
  templateUrl: './preview-tab-application.component.html',
  styleUrls: ['./preview-tab-application.component.scss']
})
export class PreviewTabApplicationComponent implements OnInit {
  @Input() isEnlargePreview: boolean; //returns true as Set zoom preview
  @Input() focusOn: string;
  @Input() languagePreview: string;
  WIDTH_PREVIEW_DEFAULT = 467;
  HEIGHT_PREVIEW_DEFAULT = 700;
  HEIGHT_CONTENT_DEFAULT = 640;
  urlTopPhoto: string; // url top photo preview
  height: string; // height content preview
  heightFooter: string;
  widthData: string;
  heightData: string;
  dataDisplaySize: DisplaySize;
  ticketsOnSaleNow: string;
  sampleTicket: string;
  sampleTicketDescription: string;
  adult: string;
  custom: string;
  yen: string;
  buy: string;
  use: string;
  scaleX: number = 1;
  scaleY: number = 1;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  /**
   * Set data Preview
   * @param data
   */
  setDataPreview(data: DataPreview): void {
    this.urlTopPhoto = data.topPhoto;
    this.elementRef.nativeElement.style.setProperty('--main-color', data.mainColor);
    this.elementRef.nativeElement.style.setProperty('--sub-color', data.subColor);
    this.elementRef.nativeElement.style.setProperty('--background-color', data.backgroundColor);
    this.elementRef.nativeElement.style.setProperty('--border-color', data.borderColor);
    this.elementRef.nativeElement.style.setProperty('--inactive-color', data.inActiveColor);
  }

  /**
   * Set display size
   * @param data
   */
  setDisplaySizePreview(data: DisplaySize): void {
    let height = data.height;
    let width = data.width;
    let scale = width / height;
    //height and width too big
    if (height > this.HEIGHT_PREVIEW_DEFAULT || width > this.WIDTH_PREVIEW_DEFAULT) {
      if (height < this.HEIGHT_PREVIEW_DEFAULT) {
        height = this.HEIGHT_PREVIEW_DEFAULT;
      } else {
        height = this.HEIGHT_PREVIEW_DEFAULT;
        width = height * scale;
      }
      if (width > this.WIDTH_PREVIEW_DEFAULT) {
        width = this.WIDTH_PREVIEW_DEFAULT;
        height = width / scale;
      }
    }
    this.widthData = `${width}px`;
    this.heightData = `${height}px`;
    this.height = `${height - 0.1 * height}px`;
    this.heightFooter = `${0.1 * height}px`;
  }

  /**
   * setLanguagePreview
   */
  setLanguagePreview(language: string): void {
    if (language == 'ja') {
      this.ticketsOnSaleNow = '販売中のチケット';
      this.sampleTicket = 'Sampleチケット';
      this.sampleTicketDescription = 'Sampleチケットの説明です';
      this.adult = '大人';
      this.custom = 'カスタム';
      this.yen = '円';
      this.buy = '買う';
      this.use = '使う';
    } else if (language == 'en') {
      this.ticketsOnSaleNow = 'Tickets on sale now';
      this.sampleTicket = 'Sample Ticket';
      this.sampleTicketDescription = 'Sample Ticket Description.';
      this.adult = 'Adult';
      this.custom = 'Custom ';
      this.yen = 'Yen';
      this.buy = 'buy';
      this.use = 'use';
    } else if (language == 'ko') {
      this.ticketsOnSaleNow = '판매 중인 티켓';
      this.sampleTicket = '샘플 티켓';
      this.sampleTicketDescription = '샘플 티켓 설명입니다.';
      this.adult = '어른';
      this.custom = '맞춤 ';
      this.yen = '엔';
      this.buy = '구매';
      this.use = '사용';
    } else if (language == 'vi') {
      this.ticketsOnSaleNow = 'Vé đang bán';
      this.sampleTicket = 'Vé mẫu';
      this.sampleTicketDescription = 'Đây là lời giải thích của vé mẫu.';
      this.adult = 'người lớn';
      this.custom = 'tùy chỉnh ';
      this.yen = 'Yên';
      this.buy = 'Mua';
      this.use = 'Sử dụng';
    } else if (language == 'zh') {
      this.ticketsOnSaleNow = '贩售中票券';
      this.sampleTicket = '样票';
      this.sampleTicketDescription = '这是样票的说明';
      this.adult = '成人';
      this.custom = '定制 ';
      this.yen = '日元';
      this.buy = '购买';
      this.use = '使用';
    } else if (language == 'zh-TW') {
      this.ticketsOnSaleNow = '販售中票券';
      this.sampleTicket = '樣票';
      this.sampleTicketDescription = '這是樣票的說明';
      this.adult = '成人';
      this.custom = '客製';
      this.yen = '圆';
      this.buy = '購買';
      this.use = '使用';
    } else if (language == 'th') {
      this.ticketsOnSaleNow = 'จำหน่ายบัตร';
      this.sampleTicket = 'ตั๋วตัวอย่าง';
      this.sampleTicketDescription = 'นี่คือคำอธิบายของตั๋วตัวอย่าง';
      this.adult = 'ผู้ใหญ่';
      this.custom = 'กำหนดเอง ';
      this.yen = 'เยน';
      this.buy = 'ซื้อ';
      this.use = 'ใช้';
    }
  }
}

export interface DataPreview {
  topPhoto: string;
  mainColor: string;
  subColor: string;
  backgroundColor: string;
  borderColor: string;
  inActiveColor: string;
}

export interface DisplaySize {
  width: number;
  height: number;
}
