import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmDeleteAppComponent } from 'app/dialog/dialog-confirm-delete-app/dialog-confirm-delete-app.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogDeliveryApplicationTicketEditorComponent } from 'app/dialog/dialog-delivery-application-ticket-editor/dialog-delivery-application-ticket-editor.component';
import { DialogDuplicateApplicationDesignComponent } from 'app/dialog/dialog-duplicate-application-design/dialog-duplicate-application-design.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { Common } from 'app/model/entity/common';
import { Image as ImageApplicaiton } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { PreviewTabApplicationComponent } from 'app/module/ticket-editor/ticket-editor-tab-application/preview-tab-application/preview-tab-application.component';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Helper } from './../../../common/helper';
import { AppDesignDTO } from './../../../model/entity/ticket-editor/dto/app-design-DTO';
@Component({
  selector: 'ticket-editor-tab-application',
  templateUrl: './ticket-editor-tab-application.component.html',
  styleUrls: ['./ticket-editor-tab-application.component.scss']
})
export class TicketEditorTabApplicationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PreviewTabApplicationComponent) previewComponent: PreviewTabApplicationComponent;
  @ViewChild('designName') designNameElementRef: ElementRef;
  @ViewChild('selectAppId') selectAppId!: ElementRef;
  @ViewChild('tablelistApp') tablelistApp!: ElementRef;
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  @Input() mapImageEnvironmentVariables: Map<string, number>;
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  @Output() languageTranslationAppSelectedOutPut: EventEmitter<any> = new EventEmitter<any>();
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  LENGTH_CHARACTER_CODE_COLOR = 6; // Length char code color
  subscriptions: Array<Subscription> = new Array<Subscription>();
  appSelected: ApplicationDTO; // app Selected
  appIdSelected: string; // app Selected
  appDesignSelectedClone: AppDesignDTO; // app Selected
  appDesignSelected: AppDesignDTO; // app Selected
  listAppDesignSelected: Array<AppDesignDTO> = new Array<AppDesignDTO>(); // list App Detail find by app selected
  designNameEdit: string; // design name edit
  listApp: ApplicationDTO[] = []; // list Application
  isEnlargePreview: boolean = false; // true if preview on
  isPlay: boolean; //true if play preview
  topPhoto: string;
  mainColor: string; // Data main color
  subColor: string; // Data sub Color
  backgroundColor: string; // Data background Color
  borderColor: string; // Data border Color
  inactiveColor: string; // Data inactive color
  isReponsive: boolean; //true if is reponsive
  languageSelected: string;
  languageSelectedPreview: string;
  focusOn: string;
  displaySize = [
    { title: 'iPhoneSE', width: '375px', height: '667px' },
    { title: 'iPhone14', width: '390px', height: '844px' },
    { title: 'iPhone14 Pro Max', width: '428px', height: '926px' },
    { title: 'iPhone13 mini', width: '375px', height: '812px' },
    { title: 'Galaxy A51', width: '412px', height: '914px' },
    { title: 'Galaxy S8', width: '360px', height: '740px' },
    { title: 'Pixel 5', width: '393px', height: '851px' },
    { title: 'Responsive', width: '375px', height: '667px' }
  ];
  colorInputs = [
    { color: 'mainColor', isCheckInput: false },
    { color: 'subColor', isCheckInput: false },
    { color: 'backgroundColor', isCheckInput: false },
    { color: 'borderColor', isCheckInput: false },
    { color: 'inActiveColor', isCheckInput: false }
  ];
  displaySizeSelected = this.displaySize[0];
  widthReponsive: number = 375; // data input width reponsive
  heightReponsive: number = 667; // data input height reponsive
  /**
   * list file data
   */
  fileData: any;
  /**
   * header column application details
   */
  headerColumnApplicationDetails: any = [
    {
      label: 'ticket-editor.top-photo',
      field: 'appTopImage'
    },
    {
      label: 'ticket-editor.main-color',
      field: 'mainColor'
    },
    {
      label: 'ticket-editor.sub-color',
      field: 'subColor'
    },
    {
      label: 'ticket-editor.background-color',
      field: 'backgroundColor'
    },
    {
      label: 'ticket-editor.border-color',
      field: 'borderColor'
    },
    {
      label: 'ticket-editor.inActive-color',
      field: 'inactiveColor'
    },
    {
      label: 'ticket-editor.set-language',
      field: 'supportedLanguageTranslation'
    }
  ];

  /**
   * header column list app
   */
  headerColumnListApp: any = [
    { headerName: 'ticket-editor.category', property: 'category' },
    { headerName: 'ticket-editor.design-name', property: 'design-name' }
  ];
  listAppDesign = []; // List app design
  isDuplicateApp: boolean = false; // Check is form duplicate application
  isEditFormApp: boolean = false; // Check is form application

  /**
   * Constant's component
   */
  private readonly FILE_MEDIA_OBJECT = 1;
  private readonly IMAGE_NAME_DROP_MEDIA = 'application';
  private readonly FILE_ATTRIBUTE = 'file';
  private readonly NAME_ATTRIBUTE = 'name';
  private readonly URL_ATTRIBUTE = 'url';

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  languages: any = new Array<any>();
  languageKey: string;
  /**
   * common object
   */
  private commonObject: Common;
  labelAddImage: string; //label Add Image

  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialogService: DialogService,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private ticketService: TicketEditorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    this.subscriptions.push(
      // Action information
      this.menuActionService.actionInformation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.informationApplication();
          }
        }
      }),
      // Action add
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          this.addNewApplication();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.editApplication();
          }
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.deleteApplication();
          }
        }
      }),
      // Action add design
      this.menuActionService.actionAddAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.addAppDesign();
          }
        }
      }),
      // Action edit design
      this.menuActionService.actionEditAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.editAppDesign();
          }
        }
      }),
      // Action delete design
      this.menuActionService.actionDeleteAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.deleteAppDesign();
          }
        }
      }),
      // Action duplicate design
      this.menuActionService.actionDuplicateAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.duplicateAppDesign();
          }
        }
      }),
      this.menuActionService.actionDelivery.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          this.deliveryApp();
        }
      })
    );

    this.commonObject = commonService.getCommonObject();
    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
      if (this.appSelected) {
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.languages = Constant.LANGUAGES_SETTING.filter(e => this.appSelected.supportedLanguage.includes(e.translation_language_code));
        const languagesCodes = this.languages.map(e => e.translation_language_code);
        const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
        this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
        this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
        this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
        if (this.previewComponent !== undefined) {
          this.previewComponent.setLanguagePreview(this.languageSelectedPreview);
        }
      }
      this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    });

    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (this.appSelected) {
          this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
          this.languages = Constant.LANGUAGES_SETTING.filter(e => this.appSelected.supportedLanguage.includes(e.translation_language_code));
          const languagesCodes = this.languages.map(e => e.translation_language_code);
          const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
          this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
          this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
          this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
          if (this.previewComponent !== undefined) {
            this.previewComponent.setLanguagePreview(this.languageSelectedPreview);
          }
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonService.getCommonObject()?.setting?.language;
    var interval = null;
    interval = setInterval(async () => {
      if (this.informationAccount != undefined) {
        clearInterval(interval);
        await this.getAllApplication(this.informationAccount);
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        if (this.listApp && this.listApp.length > 0) {
          this.selectApplication(this.listApp[Constant.FIRST_ELEMENT_INDEX].appId);
          this.appIdSelected = this.listApp[Constant.FIRST_ELEMENT_INDEX].appId;
        }
      }
    }, 50);
  }

  ngAfterViewInit(): void {
    this.setDataPreview();
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Set data for preview application
   */
  setDataPreview(str?: string, e?): void {
    if (str && e.color) {
      this[str] = e.color;
      this.convertColorToRgba(e, str);
    }
    const data = {
      topPhoto: this.appDesignSelected?.appTopImage,
      mainColor: this.mainColor && this.mainColor.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.mainColor.toLocaleUpperCase() : '',
      subColor: this.subColor && this.subColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.subColor.toLocaleUpperCase() : '',
      backgroundColor:
        this.backgroundColor && this.backgroundColor?.length > this.LENGTH_CHARACTER_CODE_COLOR
          ? this.backgroundColor.toLocaleUpperCase()
          : '',
      borderColor:
        this.borderColor && this.borderColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.borderColor.toLocaleUpperCase() : '',
      inActiveColor:
        this.borderColor && this.inactiveColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.inactiveColor.toLocaleUpperCase() : ''
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDataPreview(data);
    }
  }

  /**
   * convertColorToRgba
   * @param event
   * @param prop
   */
  convertColorToRgba(event: any, prop: string): void {
    // Remove the '#' if it's present
    let hexColor = event.color.replace('#', '');

    // Convert opacity to a valid range (0 to 1)
    const validOpacity = Math.max(0, Math.min(1, +event.opacity));

    // Convert the hex color to RGB
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Convert the components to hexadecimal and pad with zeros if needed
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    const hexOpacity = Math.round(validOpacity * 255)
      .toString(16)
      .padStart(2, '0');

    // Create the 8-character hex color code
    let hexWithOpacity = `#${hexR}${hexG}${hexB}${hexOpacity}`;
    this[prop] = hexWithOpacity;
  }

  /**
   * Set data for preview application
   *
   * @param widthReponsive
   * @param heightReponsive
   */
  setDisplaySizePreview(widthReponsive: number, heightReponsive: number): void {
    const data = {
      width: widthReponsive,
      height: heightReponsive
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDisplaySizePreview(data);
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(informationAccount?: any): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketService.findAllApplication(informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   * Select Application
   * @param app
   * @param appDesign
   */
  selectApplication(appId: string, appDesignId?: string, isSelectLastElement?: boolean): void {
    if (this.isEditFormApp) {
      return;
    }
    this.appSelected = this.listApp.find(e => e.appId == appId);
    this.appIdSelected = appId;
    this.ticketService.getAppDesignByAppId(this.informationAccount, appId).subscribe(res => {
      this.listAppDesignSelected = res;
      if (this.listAppDesignSelected?.length) {
        appDesignId = appDesignId ? appDesignId : this.listAppDesignSelected[0]?.appDesignId;
        this.selectApplicationDesign(appDesignId, isSelectLastElement);
      } else {
        this.appDesignSelected = undefined;
      }
      this.fileData = undefined;
      this.languages = Constant.LANGUAGES_SETTING.filter(e => this.appSelected.supportedLanguage?.includes(e.translation_language_code));
      const languagesCodes = this.languages.map(e => e.translation_language_code);
      const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
      this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
      this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
      this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
      if (this.previewComponent !== undefined) {
        this.previewComponent.setLanguagePreview(this.languageSelectedPreview);
      }
      this.languageTranslationAppSelectedOutPut.emit(this.appSelected.supportedLanguage);
    });
  }

  selectApplicationDesign(appDesignId: string, isSelectLastElement?: boolean): void {
    if (this.isEditFormApp) {
      return;
    }
    let indexAppDesignSelected = this.listAppDesignSelected.findIndex(e => e.appDesignId == appDesignId);
    this.appDesignSelected = this.listAppDesignSelected[indexAppDesignSelected];
    if (!this.appDesignSelected.appDesignId) {
      this.appDesignSelected = new AppDesignDTO();
    }
    if (isSelectLastElement) {
      this.changeDetectorRef.detectChanges();
      this.tablelistApp.nativeElement.scrollTop = this.tablelistApp.nativeElement.scrollHeight;
    }
    this.topPhoto = this.getFileNameFromAppTopImagePath(this.appDesignSelected?.appTopImage);
    this.mainColor = this.appDesignSelected?.mainColor;
    this.subColor = this.appDesignSelected?.subColor;
    this.backgroundColor = this.appDesignSelected?.backgroundColor;
    this.borderColor = this.appDesignSelected?.borderColor;
    this.inactiveColor = this.appDesignSelected?.inactiveColor;
    this.setDataPreview();
  }

  /**
   * Add new application element
   * @param appName
   */
  addNewApplication(appName?: string, isDuplicate?: boolean): void {
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    let appNew = new ApplicationDTO(null, null, null, null);

    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.add-application'),
          application: appNew,
          mode: Constant.MODE_ADD,
          informationAccount: this.informationAccount
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        }
        await this.getAllApplication(this.informationAccount);
        this.selectApplication(result.appId);
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      }
    );
  }

  /**
   * Edit application element
   */
  editApplication(): void {
    if (!this.appSelected) {
      return;
    }
    let application = _.cloneDeep(this.appSelected);
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.edit-application'),
          application: application,
          mode: Constant.MODE_EDIT,
          informationAccount: this.informationAccount,
          appDesignSelected: this.appDesignSelected
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        } else {
          await this.getAllApplication(this.informationAccount);
          this.changeDetectorRef.detectChanges();
          this.selectAppId.nativeElement.value = result?.appId;
          this.changeDetectorRef.detectChanges();
          this.selectApplication(result?.appId, result?.appDesignIdSelected);
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        }
      }
    );
  }

  /**
   * delete application
   */
  async deleteApplication(): Promise<void> {
    if (!this.appSelected?.appId) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmDeleteAppComponent,
      {
        data: {
          title: this.translateService.instant('ticket-editor.tilte-delete-app'),
          text: Helper.formatString(
            this.translateService.instant(`ticket-editor.confirm-delete-application`),
            `${this.appSelected?.appName}`
          ),
          button1: this.translateService.instant('ticket-editor.delete'),
          button2: this.translateService.instant('ticket-editor.cancel'),
          appName: this.appSelected?.appName
        }
      },
      async result => {
        if (!result) {
          return;
        }
        try {
          // Delete application
          await this.ticketService.deleteApplicationByAppId(this.informationAccount, this.appSelected.appId).toPromise();
          await this.getAllApplication(this.informationAccount);
          if (this.listApp?.length) {
            this.selectApplication(this.listApp[Constant.FIRST_ELEMENT_INDEX].appId);
          } else {
            this.appSelected = undefined;
            this.appDesignSelected = undefined;
            this.appIdSelected = undefined;
            this.fileData = undefined;
            this.languages = undefined;
            this.languageSelectedPreview = undefined;
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  /**
   * Check select application
   */
  checkSelectApplication(): boolean {
    if (this.appSelected) {
      return true;
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('ticket-editor.choose-app')
      }
    });
    return false;
  }

  /**
   * Check select application
   */
  checkSelectApplicationDesign(): boolean {
    if (this.appDesignSelected) {
      return true;
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('ticket-editor.choose-app-design')
      }
    });
    return false;
  }

  /**
   * add App Design element
   */
  addAppDesign(): void {
    if ((this.appDesignSelected && this.appDesignSelected.isEdit) || (this.appSelected && this.appSelected.isEdit) || !this.appSelected) {
      return;
    }
    this.isEditFormApp = true;
    this.designNameEdit = Constant.EMPTY;
    const appDesign = new AppDesignDTO();
    appDesign.isEdit = true;
    if (!this.listAppDesignSelected) {
      this.listAppDesignSelected = [];
    }
    this.listAppDesignSelected.push(appDesign);
    this.appDesignSelected = appDesign;
    this.mainColor = this.appDesignSelected?.mainColor;
    this.subColor = this.appDesignSelected?.subColor;
    this.backgroundColor = this.appDesignSelected?.backgroundColor;
    this.borderColor = this.appDesignSelected?.borderColor;
    this.inactiveColor = this.appDesignSelected?.inactiveColor;
    this.topPhoto = this.appDesignSelected?.appTopImage;
    this.setDataPreview();
    this.selectApplicationDesign(appDesign.appDesignId);
    this.changeDetectorRef.detectChanges();
    this.tablelistApp.nativeElement.scrollTop = this.tablelistApp.nativeElement.scrollHeight;
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
  }

  /**
   * Edit App Design element
   */
  editAppDesign(): void {
    if ((this.appDesignSelected && this.appDesignSelected.isEdit) || !this.appSelected) {
      return;
    }
    const index = this.listAppDesignSelected.findIndex(appDesign => appDesign.appDesignId == this.appDesignSelected.appDesignId);
    this.listAppDesignSelected[index].isEdit = true;
    this.designNameEdit = this.listAppDesignSelected[index].appDesignName;
    this.topPhoto = this.getFileNameFromAppTopImagePath(this.appDesignSelected?.appTopImage);
    this.mainColor = this.appDesignSelected?.mainColor;
    this.subColor = this.appDesignSelected?.subColor;
    this.backgroundColor = this.appDesignSelected?.backgroundColor;
    this.borderColor = this.appDesignSelected?.borderColor;
    this.inactiveColor = this.appDesignSelected?.inactiveColor;
    this.isEditFormApp = true;
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
  }

  /**
   * cancel save application
   */
  cancelSaveAppDesign(): void {
    this.appDesignSelected.isEdit = false;
    this.isEditFormApp = false;
    this.designNameEdit = Constant.EMPTY;
    if (!this.appDesignSelected.id) {
      this.listAppDesignSelected.pop();
      this.selectApplication(this.appSelected.appId);
    }
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
  }

  /**
   * validate designName when add new/ edit
   * @param designName
   */
  validateDesignName(designName: string): boolean {
    // validate app name
    if (designName.trim().length < Constant.MIN_NO_LENGTH || designName.trim().length > Constant.MAXIMUM_TEXT_LENGTH) {
      this.designNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.validate-app-design-name')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    return true;
  }

  /**
   * delete app design
   * @returns
   */
  deleteAppDesign(): void {
    if (this.appSelected?.isEdit) {
      return;
    }
    if (!this.appDesignSelected || (this.appDesignSelected && !this.appDesignSelected?.appDesignId)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app-design')
        }
      });
      return;
    }

    if (this.appDesignSelected.applied) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.error-message-when-deleting-the-app-design-being-reflected')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.confirm-delete-app-design'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.ticketService.deleteAppDesignById(this.informationAccount, this.appDesignSelected.appDesignId).subscribe(res => {
          this.selectApplication(this.appIdSelected);
          this.scrollToheFirstPositionOfTheAppList();
        });
      }
    );
  }

  /**
   * Duplicate app design
   * @returns
   */
  async duplicateAppDesign(): Promise<void> {
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    if (this.appDesignSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogDuplicateApplicationDesignComponent,
      {
        data: {
          informationAccount: this.informationAccount,
          appDesignSelected: this.appDesignSelected
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        }
        let payload = {
          appDesignName: result.designName,
          appId: result.appId,
          appTopImagePath: result.appDesignSelected.appTopImagePath,
          mainColor: result.appDesignSelected.mainColor,
          subColor: result.appDesignSelected.subColor,
          backgroundColor: result.appDesignSelected.backgroundColor,
          textColor: '#000000',
          borderColor: result.appDesignSelected.borderColor,
          inactiveColor: result.appDesignSelected.inactiveColor
        };

        this.ticketService.createAppDesign(this.informationAccount, payload).subscribe(async res => {
          await this.getAllApplication(this.informationAccount);
          this.changeDetectorRef.detectChanges();
          this.selectAppId.nativeElement.value = result?.appId;
          this.changeDetectorRef.detectChanges();
          await this.selectApplication(result.appId, res?.appDesignId, true);
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        });
      }
    );
  }

  /**
   * Play Preview
   */
  playPreview(): void {
    this.isPlay = !this.isPlay;
  }

  /**
   * change Display Size
   */
  changeDisplaySize(index: number) {
    this.displaySizeSelected = this.displaySize[index];
    this.isReponsive = this.displaySizeSelected.title == 'Responsive';
    this.widthReponsive = +this.displaySizeSelected.width.slice(0, -2);
    this.heightReponsive = +this.displaySizeSelected.height.slice(0, -2);
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * Delivery application
   */
  deliveryApp(): void {
    if (!this.appSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app')
        }
      });
      return;
    }
    if (!this.appDesignSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app-design')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogDeliveryApplicationTicketEditorComponent,
      {
        data: {
          appSelected: this.appSelected,
          appDesignSelected: this.appDesignSelected,
          informationAccount: this.informationAccount,
          languageKey: this.languageKey
        }
      },
      resultDialog => {
        if (!resultDialog) {
          return;
        }
        this.selectApplication(this.appSelected.appId, this.appDesignSelected?.appDesignId);
      }
    );
  }

  /**
   * cancel save application detail
   */
  cancelSaveApplicationDetail(): void {
    this.isEditFormApp = false;
    if (!this.appDesignSelected.appDesignId) {
      this.listAppDesignSelected.pop();
      this.selectApplication(this.appSelected.appId);
      this.scrollToheFirstPositionOfTheAppList();
    } else {
      this.selectApplication(this.appSelected.appId, this.appDesignSelected.appDesignId);
    }
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
    this.focusOn = '';
  }

  /**
   * Custom Color Hex
   * @param colorHex
   */
  customColorHex(colorHex: string): string {
    const colorCustom = colorHex ? colorHex.substring(1).toLocaleUpperCase() : '';
    return colorCustom;
  }

  /**
   * save application detail
   */
  async saveApplicationDetail(): Promise<void> {
    if (!this.validateDesignName(this.designNameEdit)) {
      return;
    }
    this.appDesignSelectedClone = _.cloneDeep(this.appDesignSelected);
    let payload = {
      appDesignId: this.appDesignSelected.appDesignId,
      appDesignName: this.designNameEdit,
      appId: this.appSelected.appId,
      appTopImagePath: this.appDesignSelected.appTopImagePath,
      mainColor: this.mainColor.toLocaleUpperCase(),
      subColor: this.subColor.toLocaleUpperCase(),
      backgroundColor: this.backgroundColor.toLocaleUpperCase(),
      textColor: '#000000',
      borderColor: this.borderColor.toLocaleUpperCase(),
      inactiveColor: this.inactiveColor.toLocaleUpperCase()
    };
    if (this.fileData) {
      const image$ = await Helper.convertFileToBase64(this.fileData as File);
      let payloadImage = {
        image: image$,
        uploadImageType: 'app_settings'
      };
      const imagePath = await new Promise<any>((resolve, reject) => {
        this.ticketService.postImage(this.informationAccount, payloadImage).subscribe(resolve, reject);
      });
      payload.appTopImagePath = imagePath?.filePath;
    }
    if (!this.appDesignSelected.appDesignId) {
      delete payload.appDesignId;
      this.ticketService.createAppDesign(this.informationAccount, payload).subscribe(res => {
        this.designNameEdit = Constant.EMPTY;
        this.isEditFormApp = false;
        this.selectApplication(this.appSelected.appId, res?.appDesignId);
        this.setDataPreview();
        this.focusOn = '';
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      });
    } else {
      delete payload.appId;
      this.ticketService.updateAppDesign(this.informationAccount, payload).subscribe(res => {
        this.designNameEdit = Constant.EMPTY;
        this.isEditFormApp = false;
        this.appDesignSelected.appDesignName = null;
        this.appDesignSelected.appTopImage = null;
        this.appDesignSelected.mainColor = null;
        this.appDesignSelected.subColor = null;
        this.appDesignSelected.backgroundColor = null;
        this.appDesignSelected.borderColor = null;
        this.appDesignSelected.inactiveColor = null;
        this.selectApplication(this.appSelected.appId, res?.appDesignId);
        this.setDataPreview();
        this.focusOn = '';
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      });
    }
  }

  /**
   * Drop media from PC
   * @param file
   */
  public async dropMediaFromPC(e: any): Promise<void> {
    const fileInput = e.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile || !this.mapImageEnvironmentVariables) {
      return;
    }
    const mediaName = selectedFile?.name.split('.')[0];
    const mediaType = selectedFile?.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!this.imageTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('index-word-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      this.fileInput.nativeElement.value = null;
      return;
    }
    if (
      !(await this.validateImage(
        selectedFile,
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_FILE_SIZE_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_WIDTH_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_HEIGHT_TAB_APPLICATION)
      ))
    ) {
      this.fileInput.nativeElement.value = null;
      return;
    }

    let fileDrop = {
      file: selectedFile,
      url: URL.createObjectURL(selectedFile),
      type: mediaType
    };
    let file = [selectedFile, fileDrop];

    const fileName = `${mediaName.split('.').shift()}_${this.IMAGE_NAME_DROP_MEDIA}.${mediaType}`;
    this.handleAfterDropMedia(mediaType, selectedFile, fileName, file);
  }

  /**
   * Handle After Drop Media
   *
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param file
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any): Promise<void> {
    let fileFromPC: Media = null;
    this.topPhoto = fileDrop?.name;
    this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
  }

  validateImage(file: File, maxFileSize: number, maxWidth: number, maxHeight: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(false);
        return;
      }

      if (file?.size > maxFileSize * 1024 * 1024) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: Helper.formatString(
              this.translateService.instant(`ticket-editor.msg.maximum-file-size`),
              `${file?.name}`,
              `${maxFileSize}`
            )
          }
        });
        resolve(false);
        return;
      }

      const img = new Image();
      let imageLoaded = false;

      img.onload = () => {
        imageLoaded = true;
        if (img.width > maxWidth) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-width`),
                `${file?.name}`,
                `${maxWidth}`
              )
            }
          });
          resolve(false);
        } else if (img.height > maxHeight) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-height`),
                `${file?.name}`,
                `${maxHeight}`
              )
            }
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!imageLoaded) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.common-error')
            }
          });
          resolve(false);
        }
      };

      img.src = URL.createObjectURL(file);
    });
  }

  /**
   * Show Image After Drop
   *
   * @param fileDrop
   * @param fileName
   * @param file
   * @param fileFromPC
   * @param mediaType
   */
  private async showImgAfterDrop(fileDrop: any, fileName: any, file: any, fileFromPC: Media, mediaType: any): Promise<void> {
    this.fileData = new File([fileDrop], fileName, { type: file[0]?.type });
    let mediaFromPC: Media = new ImageApplicaiton();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    this.appDesignSelected.appTopImage = mediaFromPC.url;
    this.setDataPreview();
  }

  /**
   * onWidth
   */
  onWidth(): void {
    if (this.widthReponsive < Constant.MIN_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MIN_WIDTH_RESPONSIVE;
    } else if (this.widthReponsive > Constant.MAX_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MAX_WIDTH_RESPONSIVE;
    }
    this.displaySize[7].width = this.widthReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onHeight
   */
  onHeight(): void {
    if (this.heightReponsive < Constant.MIN_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MIN_HEIGHT_RESPONSIVE;
    } else if (this.heightReponsive > Constant.MAX_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MAX_HEIGHT_RESPONSIVE;
    }
    this.displaySize[7].height = this.heightReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * Change Color By Input
   * @param colorType
   * @param value
   */
  changeColorByInput(colorType: string, value: string) {
    let color = `#${value}`;
    if (Constant.REGEX_COLOR_CIRCLE.test(color)) {
      this.focusOn = colorType;
      this.setDataPreview();
      this[colorType] = `#${value}`;
    }
    this.handleFocusInput(colorType);
  }

  /**
   * changeColorByPicker
   * @param prop
   * @param event
   */
  changeColorByPicker(prop: string, event: any) {
    if (prop) {
      this.focusOn = prop;
    }
  }

  /**
   * handleFocusInput
   * @param prop
   */
  handleFocusInput(prop: string): void {
    this.colorInputs.forEach(e => {
      e.isCheckInput == false;
      if (e.color == prop) {
        e.isCheckInput = true;
      }
    });
  }

  focusOut(): void {
    this.focusOn = '';
  }

  /**
   * hideColorPicker
   * @param prop
   */
  public hideColorPicker(prop: string): void {
    const color = this.colorInputs.find(e => e.color == prop);
    if (this.focusOn == prop && !color?.isCheckInput) {
      this.focusOn = '';
    }
  }

  /**
   * getNameLanguageEN
   * @param language
   * @returns
   */
  getNameLanguageEN(language: any[]): string {
    if (!language || (language && !language.length)) {
      return '';
    }
    const newlanguagesCodes = Helper.getLanguagesCode(language, this.commonService);
    let listLang = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
    listLang = Helper.reorderLanguages(listLang, this.languageSelected);
    return listLang.map(language => language.language_name_en).join(', ');
  }

  /**
   * getNameLanguageJA
   * @param language
   * @returns
   */
  getNameLanguageJA(language: any[]): string {
    if (!language || (language && !language.length)) {
      return '';
    }
    const newlanguagesCodes = Helper.getLanguagesCode(language, this.commonService);
    let listLang = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
    listLang = Helper.reorderLanguages(listLang, this.languageSelected);
    return listLang.map(language => language.language_name_ja).join('、');
  }

  /**
   * informationApplication
   */
  informationApplication() {
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.app-information'),
          application: this.appSelected,
          mode: Constant.MODE_INFORMATION
        }
      },
      result => {
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      }
    );
  }

  setLanguagePreview(): void {
    if (this.previewComponent !== undefined) {
      this.previewComponent.setLanguagePreview(this.languageSelectedPreview);
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplayApp(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }

  /**
   * fillDataAppDesign
   */
  fillDataAppDesign() {
    this.selectApplication(this.appIdSelected);
  }

  /**
   * getFileNameFromAppTopImagePath
   * @param appTopImagePath
   * @returns
   */
  getFileNameFromAppTopImagePath(appTopImagePath: string): string {
    if (!appTopImagePath || appTopImagePath.indexOf('/') === -1) {
      return '';
    }
    const filenameWithExtension = appTopImagePath.substring(appTopImagePath.lastIndexOf('/') + 1, appTopImagePath.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    return filename;
  }

  /**
   * getTitleSelected
   * @param appId
   * @returns
   */
  getTitleSelected(appId: string): string {
    let indexApp = this.listApp.findIndex(e => e.appId == appId);
    if (indexApp == -1) {
      return '';
    } else {
      return this.listApp[indexApp].appName;
    }
  }

  /**
   * scrollToheFirstPositionOfTheAppList
   */
  scrollToheFirstPositionOfTheAppList(): void {
    try {
      this.tablelistApp.nativeElement.scrollTop = 0;
    } catch (err) {
      console.log(err);
    }
  }
}

export interface Language {
  ja: string;
  en: string;
}
