import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import { Ticket } from 'app/model/entity/ticket';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { SaleTicket } from 'app/model/entity/ticket-editor/set-ticket';
import { CombinationTicket, SaleTicketDetail } from 'app/model/entity/ticket-editor/set-ticket-detail';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { CancelPolicies } from './../../../model/entity/ticket-editor/set-ticket-detail';
@Component({
  selector: 'ticket-editor-tab-combination',
  templateUrl: './ticket-editor-tab-combination.component.html',
  styleUrls: ['./ticket-editor-tab-combination.component.scss']
})
export class TicketEditorTabCombinationComponent implements OnInit, OnDestroy {
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  @Input() appId: string;
  @Input() announcementManagerService: AnnouncementManagerService;

  @ViewChild('detailContainer', { static: false }) detailContainer: ElementRef;
  @ViewChild('listDisplay', { static: false }) listDisplay: ElementRef;
  @ViewChild('listTicket', { static: false }) listTicket: ElementRef;
  @ViewChildren('formInput') formInputs: QueryList<ElementRef>;
  @ViewChildren('formInputFormCancellationFee') formInputFormCancellationFee: QueryList<ElementRef>;
  appName: string;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  listCombinationTicket = []; // List of tickets
  listCombinationTicketOrigin = []; // List of tickets
  listSaleTicket: Array<SaleTicket>; // List of ticketsThe list of tickets that have made the set
  setTicketSelected: SaleTicket; // Set ticket is selected
  ticketSetDetail: SaleTicketDetail; // Data detail of set ticket
  combinationTicketClone: Array<CombinationTicket> = new Array<CombinationTicket>(); // Data detail of set ticket
  placeholderSelectDate: string; // Placeholder for input type date
  isEnlargePreview: boolean = false; // true if preview on
  isEditSaleTicketDetail: boolean = false; // Check editing set ticket detail
  formSaleTicket: FormGroup; // Form sale ticket
  formCancellationFee: FormGroup; // Form sale ticket
  isDragDrop: boolean;
  draggedItem: any;
  languages: any = new Array<any>();
  updateScheduleAtTime = '00:00';
  saleStartAtTime = '00:00';
  saleEndAtTime = '00:00';
  isChooseDetailUpdate: boolean;
  timezone: any;
  public languageKey: string; //language key
  updateScheduleAtValue: any;
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  titleFormData: string;
  titleEditSetTicket: string;
  titleEditReservation: string;
  titleAddReservation: string;
  titleNewRegistrationSetTicket: string;
  titleDuplicateSetTicket: string;
  isEditType: boolean;
  listApp: ApplicationDTO[] = []; // list Application
  formatDate: string;
  languageSelected: string;
  LANGUAGES_SETTING = Constant.LANGUAGES_SETTING;
  listSaleTicketDisplay: any[];
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  isShowPopUpSortFilter: boolean;
  columnSortFiltering: string;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];
  sortFilterObject: SortFilterObject;
  mapFilterOption = new Map();
  headerColumns: any[] = [
    { headerName: 'ticket-editor.combination.set-ticket-id', property: 'saleTicketId' },
    { headerName: 'ticket-editor.combination.set-ticket-name', property: 'ticketName' },
    { headerName: 'ticket-editor.combination.type', property: 'ticketType' },
    { headerName: 'ticket-editor.combination.reservation-status', property: 'isReserve' },
    { headerName: 'ticket-editor.combination.app-name', property: 'appName' },
    { headerName: 'ticket-editor.combination.sales-price', property: 'salesPrice' },
    { headerName: 'ticket-editor.combination.schedule-update', property: 'updateScheduleAt' },
    { headerName: 'ticket-editor.combination.sales-status', property: 'salesStatus' }
  ]; // List header ticket filter
  columns = ['setTicketId', 'setTicketName', 'appName', 'salesPrice', 'updateScheduleAt', 'salesStatus'];
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  base64Selected: any;
  numbers = Array.from({ length: 21 }, (_, i) => i * 5);
  helper = Helper;
  readonly IS_FORM_SALE_TICKET = 'isFormSaleTicket';
  readonly PRICE_CLASS = 1;
  readonly PER_TICKET = 2;
  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private ticketEditorService: TicketEditorService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    // Action menu combination
    this.subscriptions.push(
      // add new set ticket
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.addNewSaleTicketElement();
        }
      }),
      // Edit set ticket
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.editDetailSaleTicket();
        }
      }),
      // Duplicate set ticket
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.duplicateSaleTicket();
        }
      }),
      // Delete set ticket
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.deleteSaleTicket();
        }
      }),
      // add new set ticket
      this.menuActionService.actionAddReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.addSaleTicketUpdate();
        }
      }),
      // Edit set ticket
      this.menuActionService.actionEditReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.editSaleTicketUpdate();
        }
      }),
      // Delete set ticket
      this.menuActionService.actionDeleteReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.deleteSaleTicketUpdate();
        }
      }),
      this.menuActionService.actionSortFilterSaleInfo.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.sortAndFilter();
        }
      }),
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.translateTicket();
        }
      })
    );
    // Set text placeholder
    this.placeholderSelectDate = this.translateService.instant('ticket-editor.combination.select-date');

    // Get language
    this.translateService.onLangChange.subscribe(() => {
      // Translate title
      this.placeholderSelectDate = this.translateService.instant('ticket-editor.combination.select-date');
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.changeTitleFormData();
        // this.defineLanguages();
        if (!this.isEditSaleTicketDetail) {
          this.getListSaleTicket(null, 'TOP');
        }
      })
    );
    this.titleFormData = '';
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });

    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (this.languages?.length) {
          this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
          this.languages = this.reorderLanguages(this.languages, this.languageSelected);
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
    await this.getAllApplication();
    //Get data
    this.initFormSaleTicket();
    this.initFormCancellationFee();
    this.getListSaleTicket();
    this.timezone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '')
      .replace('GMT', '');
    if (!this.timezone) {
      this.timezone = 'Z';
    }
    this.formatDate = 'yyyy-MM-dd';
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Get list sale ticket
   * @param setTicketId
   */
  getListSaleTicket(setTicketId?: number, isMovePosition?: string): void {
    this.ticketEditorService.getSalesTickets(this.informationAccount).subscribe(data => {
      this.listSaleTicket = data;
      if (this.listSaleTicket.length) {
        this.listSaleTicketDisplay = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket));
        if (isMovePosition && isMovePosition == Constant.BOTTOM_ENUM) {
          this.changeDetectorRef.detectChanges();
          if (this.listDisplay) {
            this.listDisplay.nativeElement.scrollTop = this.listDisplay.nativeElement.scrollHeight;
          }
        } else if (isMovePosition && isMovePosition == Constant.TOP_ENUM) {
          this.changeDetectorRef.detectChanges();
          if (this.listDisplay) {
            this.listDisplay.nativeElement.scrollTop = 0;
          }
        }
        // Get detail set ticket
        setTicketId = setTicketId ? setTicketId : this.listSaleTicket[0].saleTicketId;
        this.setTicketSelected = this.setTicketSelected ? this.setTicketSelected : this.listSaleTicket[0];
        this.getDetailTicketSet(setTicketId, null);
      } else {
        this.setTicketSelected = null;
        this.ticketSetDetail = null;
        this.listCombinationTicket = [];
        this.listCombinationTicketOrigin = [];
        this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, true]);
      }
    }),
      error => Helper.getErrorTicket(error, this.translateService, this.dialogService);
  }

  /**
   * Get detail set ticket
   * @param setTicketId
   */
  async getDetailTicketSet(setTicketId: number, isSelecting?: boolean): Promise<void> {
    if (isSelecting) {
      this.isChooseDetailUpdate = false;
    }
    this.updateScheduleAtTime = '00:00';
    this.saleStartAtTime = '00:00';
    this.saleEndAtTime = '00:00';
    forkJoin({
      saleTicketDetail: this.ticketEditorService.getSalesTicket(this.informationAccount, setTicketId),
      saleTicketDetailUpdate: this.ticketEditorService.getSalesTicketUpdate(this.informationAccount, setTicketId)
    }).subscribe(
      async data => {
        const index = this.listSaleTicket.findIndex(saleTicket => saleTicket.saleTicketId == setTicketId);
        this.setTicketSelected = this.listSaleTicket[index];
        this.setTicketSelected.saleTicketDetail = data.saleTicketDetail;
        if (data?.saleTicketDetail) {
          if (
            !data.saleTicketDetail?.cancelPolicies ||
            (data.saleTicketDetail.cancelPolicies && data.saleTicketDetail.cancelPolicies.length == 0)
          ) {
            data.saleTicketDetail.cancelPolicies = [];
          }
        }
        if (data?.saleTicketDetailUpdate) {
          if (
            !data.saleTicketDetailUpdate?.cancelPolicies ||
            (data.saleTicketDetailUpdate.cancelPolicies && data.saleTicketDetailUpdate.cancelPolicies.length == 0)
          ) {
            data.saleTicketDetailUpdate.cancelPolicies = [];
          }
        }
        this.defineLanguages(this.setTicketSelected.saleTicketDetail.appId);
        this.setTicketSelected.saleTicketDetailReserved = data.saleTicketDetailUpdate;
        if (this.setTicketSelected.saleTicketDetail.saleTicketUrl && !this.setTicketSelected.saleTicketDetail.qrBase64) {
          let qrCoderesult = await Helper.generateQRCode(this.setTicketSelected.saleTicketDetail.saleTicketUrl);
          this.base64Selected = _.cloneDeep(qrCoderesult);
          this.setTicketSelected.saleTicketDetail.qrBase64 = this.sanitizer.bypassSecurityTrustHtml(this.base64ToSvg(this.base64Selected));
          this.setTicketSelected.saleTicketDetail.dataQr = _.cloneDeep(qrCoderesult);
        } else {
          this.base64Selected = this.setTicketSelected.saleTicketDetail.dataQr;
        }
        if (this.setTicketSelected.saleTicketDetail.saleStartAt) {
          const tmp = this.setTicketSelected.saleTicketDetail.saleStartAt.split('T');
          const startTime = tmp[1].split(':');
          this.saleStartAtTime = `${startTime[0]}:${startTime[1]}`;
        }
        if (this.setTicketSelected.saleTicketDetail.saleEndAt) {
          const tmp = this.setTicketSelected.saleTicketDetail.saleEndAt.split('T');
          const endTime = tmp[1].split(':');
          this.saleEndAtTime = `${endTime[0]}:${endTime[1]}`;
        }
        if (this.setTicketSelected.saleTicketDetailReserved.updateScheduleAt) {
          const tmp = this.setTicketSelected.saleTicketDetailReserved.updateScheduleAt.split('T');
          const time = tmp[1].split(':');
          this.updateScheduleAtTime = `${time[0]}:${time[1]}`;
          this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, false]);
        } else {
          this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, true]);
        }
        this.ticketSetDetail = this.isChooseDetailUpdate
          ? this.setTicketSelected.saleTicketDetailReserved
          : this.setTicketSelected.saleTicketDetail;
        this.combinationTicketClone = _.cloneDeep(this.ticketSetDetail.combinationTicket);
        await this.getListCombinationTicket(this.setTicketSelected.appId);
        this.appName = this.setTicketSelected.appName;
        this.changeDetectorRef.detectChanges();
        if (this.detailContainer) {
          this.detailContainer.nativeElement.scrollTop = 0;
        }
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        return;
      }
    );
  }

  /**
   * base64ToSvg
   * @param base64
   * @returns
   */
  private base64ToSvg(base64: string | null): string | null {
    return base64;
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.findAllApplication(this.informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   * Add new set ticket element
   */
  async addNewSaleTicketElement(): Promise<void> {
    this.isDragDrop = true;
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.new-registration-set-ticket');
    this.titleNewRegistrationSetTicket = this.titleFormData;
    this.changeDetailUpdate(false);
    const data = new SaleTicketDetail();
    data.isDisplayInformationTicketName = true;
    data.isDisplayInformationCompanyName = true;
    data.isDisplayInformationSubtitle = true;
    data.isDisplayInformationNote = true;
    data.isDisplayInformationNote1 = true;
    data.isDisplayInformationNote2 = true;
    data.isDisplayInformationNote3 = true;
    data.isDisplayInformationSalesName1 = true;
    data.isDisplayInformationSalesName2 = true;
    data.isDisplayInformationSalesName3 = true;
    data.isDisplayCancellationCharge = true;
    await [(this.ticketSetDetail = data)];
    this.saleStartAtTime = '00:00';
    this.saleEndAtTime = '00:00';
    this.languages = _.cloneDeep([this.languageSelected]);
    this.deleteMultilingualFields();
    this.updateMultilingualFields(this.languages);
    this.languages.forEach(element => {
      this.formSaleTicket.get(`companyName${element}`).disable();
    });
    // Select row set ticket
    this.isEditSaleTicketDetail = true;
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
    this.listCombinationTicket = [];
    this.listCombinationTicketOrigin = [];
    this.formSaleTicket.get('updateScheduleAt').clearValidators();
    this.formSaleTicket.get('updateScheduleAt').updateValueAndValidity();
    this.ticketSetDetail.cancelPolicies = [new CancelPolicies(0, 'days', 0), new CancelPolicies(1, 'days', 0)];
  }

  /**
   * duplicateSaleTicket
   */
  duplicateSaleTicket(): void {
    if (!this.setTicketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.isDragDrop = true;
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.duplicate-set-ticket');
    this.titleDuplicateSetTicket = this.titleFormData;
    let saleTicketDuplicate = _.cloneDeep(this.setTicketSelected.saleTicketDetail);
    saleTicketDuplicate.saleTicketId = null;
    this.ticketSetDetail = saleTicketDuplicate;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    this.ticketSetDetail.isDisplayCancellationCharge = true;
    let indexApp = this.listApp?.findIndex(e => e.appId == this.ticketSetDetail.appId);
    this.languages = [];
    if (indexApp !== -1) {
      this.languages = Constant.LANGUAGES_SETTING.filter(e =>
        this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
      )?.map(e => e.translation_language_code);
      this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
      this.languages = this.reorderLanguages(this.languages, this.languageSelected);
    }
    this.deleteMultilingualFields();
    this.updateMultilingualFields(this.languages);
    this.languages.forEach(element => {
      this.formSaleTicket.get(`companyName${element}`).disable();
    });
    if (this.hasReserveTickets()) {
      this.listCombinationTicket = _.cloneDeep(this.listCombinationTicket.filter(e => !e.isReserve));
      if (!this.ticketSetDetail?.cancelPolicies?.length) {
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'F'
        });
        this.checkChangeCancellationPolicySettingStatus('F');
      } else {
        this.deleteAllValidateCancelFee();
        for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
          const policy = this.ticketSetDetail.cancelPolicies[index];
          if (index == 0) {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit));
          } else {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period, [this.noWhitespaceValidator]));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit, [Validators.required]));
          }
          this.formCancellationFee.addControl(
            `cancellationFeePercentage${index}`,
            this.formBuilder.control(policy.cancellationFeePercentage, [Validators.required])
          );
        }
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'T'
        });
        this.checkChangeCancellationPolicySettingStatus('T');
        this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
      }
    }

    if (!this.ticketSetDetail.cancelPolicies || (this.ticketSetDetail.cancelPolicies && !this.ticketSetDetail.cancelPolicies.length)) {
      this.ticketSetDetail.cancelPolicies = [new CancelPolicies(0, 'days', 0), new CancelPolicies(1, 'days', 0)];
    }
    this.isEditSaleTicketDetail = true;
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    this.fillValueToForm(this.ticketSetDetail);
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
    this.formSaleTicket.get('updateScheduleAt').clearValidators();
    this.formSaleTicket.get('updateScheduleAt').updateValueAndValidity();
  }

  /**
   * Delete set ticket
   */
  deleteSaleTicket(): void {
    if (!this.listSaleTicket.length || !this.setTicketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.combination.confirm-delete-checked-set-ticket'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payload = {
          saleTicketId: this.setTicketSelected.saleTicketId
        };
        this.ticketEditorService.deleteSaleTicket(this.informationAccount, payload).subscribe(
          (data: any) => {
            const saleTicketId = data;
            this.getListSaleTicket(null, Constant.TOP_ENUM);
          },
          (error: any) => {
            return;
          }
        );
      }
    );
  }

  /**
   * Edit detail set ticket element
   */
  editDetailSaleTicket(): void {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.edit-set-ticket');
    this.titleEditSetTicket = this.titleFormData;
    this.isEditType = true;
    this.changeDetailUpdate(false);
    this.isEditSaleTicketDetail = true;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationCompanyName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    this.ticketSetDetail.isDisplayCancellationCharge = true;
    let indexApp = this.listApp?.findIndex(e => e.appId == this.ticketSetDetail.appId);
    this.languages = [];
    if (indexApp !== -1) {
      this.languages = Constant.LANGUAGES_SETTING.filter(e =>
        this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
      )?.map(e => e.translation_language_code);
      this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
      this.languages = this.reorderLanguages(this.languages, this.languageSelected);
    }
    this.deleteMultilingualFields();
    this.updateMultilingualFields(this.languages);
    this.fillValueToForm(this.ticketSetDetail);
    if (this.hasReserveTickets()) {
      this.listCombinationTicket = _.cloneDeep(this.listCombinationTicket.filter(e => !e.isReserve));
      if (!this.ticketSetDetail?.cancelPolicies?.length) {
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'F'
        });
        this.checkChangeCancellationPolicySettingStatus('F');
      } else {
        this.deleteAllValidateCancelFee();
        for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
          const policy = this.ticketSetDetail.cancelPolicies[index];
          if (index == 0) {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit));
          } else {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period, [this.noWhitespaceValidator]));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit, [Validators.required]));
          }
          this.formCancellationFee.addControl(
            `cancellationFeePercentage${index}`,
            this.formBuilder.control(policy.cancellationFeePercentage, [Validators.required])
          );
        }
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'T'
        });
        this.checkChangeCancellationPolicySettingStatus('T');
        this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
      }
    }

    if (!this.ticketSetDetail.cancelPolicies || (this.ticketSetDetail.cancelPolicies && !this.ticketSetDetail.cancelPolicies.length)) {
      this.ticketSetDetail.cancelPolicies = [new CancelPolicies(0, 'days', 0), new CancelPolicies(1, 'days', 0)];
    }
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
    this.formSaleTicket.get('updateScheduleAt').clearValidators();
    this.formSaleTicket.get('updateScheduleAt').updateValueAndValidity();
  }

  /**
   * Update detail set ticket
   */
  async updateDetailSaleTicket(): Promise<void> {
    if (this.titleAddReservation || this.titleEditReservation) {
      this.formSaleTicket.get('appId').disable();
    }
    this.formSaleTicket.updateValueAndValidity();
    if (
      this.formSaleTicket.valid &&
      (this.formSaleTicket.value?.adultSelection ||
        this.formSaleTicket.value?.custom1Selection ||
        this.formSaleTicket.value?.custom2Selection ||
        this.formSaleTicket.value?.custom3Selection)
    ) {
      const combinationTicket = this.ticketSetDetail.combinationTicket;
      if (!combinationTicket?.length) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.combination.error-combinationTicket')
          }
        });
        return;
      } else {
        if (
          this.hasReserveTickets() &&
          this.formSaleTicket?.value.cancellationPolicySettingStatus == 'T' &&
          this.formCancellationFee.invalid
        ) {
          this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
          return;
        }

        if (combinationTicket?.some((item, index) => combinationTicket.findIndex(other => other.ticketId === item.ticketId) !== index)) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('ticket-editor.combination.error-duplicate-ticketId')
            }
          });
          return;
        }
      }
      this.getDataFromForm();
    } else {
      this.languages.forEach(element => {
        if (this.f[`saleTicketName${element}`].errors && !this.ticketSetDetail?.isDisplayInformationTicketName) {
          this.ticketSetDetail.isDisplayInformationTicketName = true;
        }
        if (this.f[`subtitle${element}`].errors && !this.ticketSetDetail?.isDisplayInformationSubtitle) {
          this.ticketSetDetail.isDisplayInformationSubtitle = true;
        }
        if (this.f[`note${element}`].errors && !this.ticketSetDetail?.isDisplayInformationNote) {
          this.ticketSetDetail.isDisplayInformationNote = true;
        }
        if (this.f[`custom1Name${element}`].errors && !this.ticketSetDetail?.isDisplayInformationSalesName1) {
          this.ticketSetDetail.isDisplayInformationSalesName1 = true;
        }
        if (this.f[`custom2Name${element}`].errors && !this.ticketSetDetail?.isDisplayInformationSalesName2) {
          this.ticketSetDetail.isDisplayInformationSalesName2 = true;
        }
        if (this.f[`custom3Name${element}`].errors && !this.ticketSetDetail?.isDisplayInformationSalesName3) {
          this.ticketSetDetail.isDisplayInformationSalesName3 = true;
        }
      });
      this.markTheElement(this.formSaleTicket, 'SaleTicketForm');
      if (this.hasReserveTickets() && this.formSaleTicket?.value.cancellationPolicySettingStatus == 'T') {
        this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
      }
    }
  }

  /**
   * getDataFromForm
   */
  getDataFromForm(): void {
    const formValue = this.formSaleTicket.value;
    const formCancellationFeeValue = this.formCancellationFee.value;
    let payload: any;
    let payloadCancelPolicies: CancelPolicies[] = [];
    const combinationTicket = this.ticketSetDetail.combinationTicket
      ? this.ticketSetDetail.combinationTicket.map((e, index) => ({
          ticketId: e.ticketId,
          displayPriority: index + 1
        }))
      : [];

    let saleTicketName = {};
    this.languages.forEach(element => {
      if (formValue[`saleTicketName${element}`]) {
        saleTicketName[element] = formValue[`saleTicketName${element}`] ? formValue[`saleTicketName${element}`] : '';
      }
    });

    let subtitle = {};
    this.languages.forEach(element => {
      subtitle[element] = formValue[`subtitle${element}`] ? formValue[`subtitle${element}`] : '';
    });

    let note = {};
    this.languages.forEach(element => {
      note[element] = formValue[`note${element}`] ? formValue[`note${element}`] : '';
    });

    let custom1Name = {};
    this.languages.forEach(element => {
      if (formValue[`custom1Name${element}`]) {
        custom1Name[element] = formValue[`custom1Name${element}`] ? formValue[`custom1Name${element}`] : null;
      }
    });

    let custom1Note = {};
    this.languages.forEach(element => {
      custom1Note[element] = formValue[`custom1Note${element}`] ? formValue[`custom1Note${element}`] : '';
    });

    let custom2Name = {};
    this.languages.forEach(element => {
      if (formValue[`custom2Name${element}`]) {
        custom2Name[element] = formValue[`custom2Name${element}`] ? formValue[`custom2Name${element}`] : null;
      }
    });

    let custom2Note = {};
    this.languages.forEach(element => {
      custom2Note[element] = formValue[`custom2Note${element}`] ? formValue[`custom2Note${element}`] : '';
    });

    let custom3Name = {};
    this.languages.forEach(element => {
      if (formValue[`custom3Name${element}`]) {
        custom3Name[element] = formValue[`custom3Name${element}`] ? formValue[`custom3Name${element}`] : null;
      }
    });

    let custom3Note = {};
    this.languages.forEach(element => {
      custom3Note[element] = formValue[`custom3Note${element}`] ? formValue[`custom3Note${element}`] : '';
    });

    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleNewRegistrationSetTicket:
          if (this.hasReserveTickets() && formValue.cancellationPolicySettingStatus == 'T') {
            for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
              let formElement = {
                period: +formCancellationFeeValue[`period${index}`],
                periodUnit: formCancellationFeeValue[`periodUnit${index}`],
                cancellationFeePercentage: +formCancellationFeeValue[`cancellationFeePercentage${index}`]
              };
              payloadCancelPolicies.push(formElement);
            }
          }
          // Add sales ticket
          payload = {
            saleTicketName: saleTicketName,
            appId: formValue.appId,
            operatorId: +this.informationAccount.compnanyid,
            combinationTicket: combinationTicket,
            subtitle: subtitle,
            note: note,
            saleStartAt: moment(formValue.saleStartAt).format('YYYY-MM-DD'),
            saleEndAt: formValue.saleEndAt ? moment(formValue.saleEndAt).format('YYYY-MM-DD') : undefined,
            currencyUnit: 'JPY',
            priceAdult:
              formValue.priceType == 'no_cost' && formValue?.adultSelection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceAdult && formValue?.adultSelection
                ? formValue.priceAdult
                : undefined,
            priceType: formValue.priceType,
            custom1Name: custom1Name && formValue?.custom1Selection ? custom1Name : undefined,
            custom1Note: custom1Note && formValue?.custom1Selection ? custom1Note : undefined,
            custom2Name: custom2Name && formValue?.custom2Selection ? custom2Name : undefined,
            custom2Note: custom2Note && formValue?.custom2Selection ? custom2Note : undefined,
            custom3Name: custom3Name && formValue?.custom3Selection ? custom3Name : undefined,
            custom3Note: custom3Note && formValue?.custom3Selection ? custom3Note : undefined,
            priceCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom1 && formValue?.custom1Selection
                ? formValue.priceCustom1
                : undefined,
            priceCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom2 && formValue?.custom2Selection
                ? formValue.priceCustom2
                : undefined,
            priceCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom3 && formValue?.custom3Selection
                ? formValue.priceCustom3
                : undefined,
            refundFeeAdult:
              formValue.priceType == 'no_cost' && formValue.adultSelection
                ? null
                : !Helper.isEmpty(formValue.refundFeeAdult) &&
                  formValue.adultSelection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeAdult
                : undefined,
            refundFeeCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom1) &&
                  formValue?.custom1Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom1
                : undefined,
            refundFeeCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom2) &&
                  formValue?.custom2Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom2
                : undefined,
            refundFeeCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom3) &&
                  formValue?.custom3Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom3
                : undefined,
            refundFeeTicket:
              formValue.priceType == 'no_cost'
                ? null
                : !Helper.isEmpty(formValue.refundFeeTicket) && formValue.refundFeeMethod == Constant.PER_TICKET_VALUE
                ? formValue.refundFeeTicket
                : undefined,
            minCountAdult: !Helper.isEmpty(formValue.minCountAdult) && formValue?.adultSelection ? formValue.minCountAdult : undefined,
            maxCountAdult: !Helper.isEmpty(formValue.maxCountAdult) && formValue?.adultSelection ? formValue.maxCountAdult : undefined,
            minCountCustom1:
              !Helper.isEmpty(formValue.minCountCustom1) && formValue?.custom1Selection ? formValue.minCountCustom1 : undefined,
            maxCountCustom1:
              !Helper.isEmpty(formValue.maxCountCustom1) && formValue?.custom1Selection ? formValue.maxCountCustom1 : undefined,
            minCountCustom2:
              !Helper.isEmpty(formValue.minCountCustom2) && formValue?.custom2Selection ? formValue.minCountCustom2 : undefined,
            maxCountCustom2:
              !Helper.isEmpty(formValue.maxCountCustom2) && formValue?.custom2Selection ? formValue.maxCountCustom2 : undefined,
            minCountCustom3:
              !Helper.isEmpty(formValue.minCountCustom3) && formValue?.custom3Selection ? formValue.minCountCustom3 : undefined,
            maxCountCustom3:
              !Helper.isEmpty(formValue.maxCountCustom3) && formValue?.custom3Selection ? formValue.maxCountCustom3 : undefined,
            availablePeriod: formValue.availablePeriod ?? undefined,
            availablePeriodUnit: formValue.availablePeriodUnit ?? undefined,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : undefined,
            refundPeriod: formValue.priceType == 'no_cost' ? null : formValue.refundPeriod ?? undefined,
            refundPeriodUnit: formValue.priceType == 'no_cost' ? null : formValue.refundPeriodUnit ?? undefined,
            refundDeadlineAt:
              formValue.priceType == 'no_cost'
                ? null
                : formValue.refundDeadlineAt
                ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD')
                : undefined,
            cancelPolicies:
              this.hasReserveTickets() && formValue.cancellationPolicySettingStatus == 'T' ? payloadCancelPolicies : undefined,
            maxParticipantCount: formValue.salesLimitStatus ? null : +formValue.maxParticipantCount,
            isDisplayRemainingCount: formValue.salesLimitStatus ? false : formValue.isDisplayRemainingCount,
            voucherType: formValue.voucherType,
            fixedVoucherCode: formValue.voucherType == 'none' ? null : formValue.fixedVoucherCode,
            isDisplayAnonymousForm: formValue.isDisplayAnonymousForm
          };
          if (!Object.keys(subtitle).length) {
            delete payload.subtitle;
          }
          if (!Object.keys(note).length) {
            delete payload.note;
          }
          if (!Object.keys(custom1Name).length) {
            delete payload.custom1Name;
          }
          if (!Object.keys(custom1Note).length) {
            delete payload.custom1Note;
          }
          if (!Object.keys(custom2Name).length) {
            delete payload.custom2Name;
          }
          if (!Object.keys(custom2Note).length) {
            delete payload.custom2Note;
          }
          if (!Object.keys(custom3Name).length) {
            delete payload.custom3Name;
          }
          if (!Object.keys(custom3Note).length) {
            delete payload.custom3Note;
          }
          if (this.hasReserveTickets()) {
            delete payload.refundPeriod;
            delete payload.refundPeriodUnit;
            delete payload.refundDeadlineAt;
          }
          if (!this.hasNoReserveTickets()) {
            delete payload.availablePeriod;
            delete payload.availablePeriodUnit;
            delete payload.availableDeadlineAt;
          }
          //set start date
          const selectedStartTime = new Date(formValue.saleStartAt);
          const startDateInfoEditor = {
            year: selectedStartTime.getFullYear(),
            month: selectedStartTime.getMonth(),
            day: selectedStartTime.getDate(),
            hour: parseInt(this.saleStartAtTime.split(':')[0]),
            minute: parseInt(this.saleStartAtTime.split(':')[1]),
            second: 0
          };
          const momentStartObjectEditor = moment(startDateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
          payload[`saleStartAt`] = momentStartObjectEditor + this.timezone;

          //set end date
          if (!formValue.saleEndAt) {
            payload[`saleEndAt`] = undefined;
          } else {
            const selectedEndTime = new Date(formValue.saleEndAt);
            const endDateInfoEditor = {
              year: selectedEndTime.getFullYear(),
              month: selectedEndTime.getMonth(),
              day: selectedEndTime.getDate(),
              hour: parseInt(this.saleEndAtTime.split(':')[0]),
              minute: parseInt(this.saleEndAtTime.split(':')[1]),
              second: 0
            };
            const momentEndObjectEditor = moment(endDateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
            payload[`saleEndAt`] = momentEndObjectEditor + this.timezone;
          }
          this.ticketEditorService.postSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.isChooseDetailUpdate = false;
              this.deleteTitle();
              this.getListSaleTicket(data.saleTicketId as number, Constant.BOTTOM_ENUM);
              this.initFormSaleTicket();
              this.initFormCancellationFee();
              this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
              this.isDragDrop = false;
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleDuplicateSetTicket:
          if (this.hasReserveTickets() && formValue.cancellationPolicySettingStatus == 'T') {
            for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
              let formElement = {
                period: +formCancellationFeeValue[`period${index}`],
                periodUnit: formCancellationFeeValue[`periodUnit${index}`],
                cancellationFeePercentage: +formCancellationFeeValue[`cancellationFeePercentage${index}`]
              };
              payloadCancelPolicies.push(formElement);
            }
          }
          //duplicate set ticket
          payload = {
            saleTicketName: saleTicketName,
            appId: formValue.appId,
            operatorId: +this.informationAccount.compnanyid,
            combinationTicket: combinationTicket,
            subtitle: subtitle,
            note: note,
            currencyUnit: 'JPY',
            priceAdult:
              formValue.priceType == 'no_cost' && formValue?.adultSelection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceAdult && formValue?.adultSelection
                ? formValue.priceAdult
                : undefined,
            priceType: formValue.priceType,
            custom1Name: custom1Name && formValue?.custom1Selection ? custom1Name : undefined,
            custom1Note: custom1Note && formValue?.custom1Selection ? custom1Note : undefined,
            custom2Name: custom2Name && formValue?.custom2Selection ? custom2Name : undefined,
            custom2Note: custom2Note && formValue?.custom2Selection ? custom2Note : undefined,
            custom3Name: custom3Name && formValue?.custom3Selection ? custom3Name : undefined,
            custom3Note: custom3Note && formValue?.custom3Selection ? custom3Note : undefined,
            priceCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom1 && formValue?.custom1Selection
                ? formValue.priceCustom1
                : undefined,
            priceCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom2 && formValue?.custom2Selection
                ? formValue.priceCustom2
                : undefined,
            priceCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom3 && formValue?.custom3Selection
                ? formValue.priceCustom3
                : undefined,
            refundFeeAdult:
              formValue.priceType == 'no_cost' && formValue.adultSelection
                ? null
                : !Helper.isEmpty(formValue.refundFeeAdult) &&
                  formValue.adultSelection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeAdult
                : undefined,
            refundFeeCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom1) &&
                  formValue?.custom1Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom1
                : undefined,
            refundFeeCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom2) &&
                  formValue?.custom2Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom2
                : undefined,
            refundFeeCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom3) &&
                  formValue?.custom3Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom3
                : undefined,
            refundFeeTicket:
              formValue.priceType == 'no_cost'
                ? null
                : !Helper.isEmpty(formValue.refundFeeTicket) && formValue.refundFeeMethod == Constant.PER_TICKET_VALUE
                ? formValue.refundFeeTicket
                : undefined,
            minCountAdult: !Helper.isEmpty(formValue.minCountAdult) && formValue?.adultSelection ? formValue.minCountAdult : undefined,
            maxCountAdult: !Helper.isEmpty(formValue.maxCountAdult) && formValue?.adultSelection ? formValue.maxCountAdult : undefined,
            minCountCustom1:
              !Helper.isEmpty(formValue.minCountCustom1) && formValue?.custom1Selection ? formValue.minCountCustom1 : undefined,
            maxCountCustom1:
              !Helper.isEmpty(formValue.maxCountCustom1) && formValue?.custom1Selection ? formValue.maxCountCustom1 : undefined,
            minCountCustom2:
              !Helper.isEmpty(formValue.minCountCustom2) && formValue?.custom2Selection ? formValue.minCountCustom2 : undefined,
            maxCountCustom2:
              !Helper.isEmpty(formValue.maxCountCustom2) && formValue?.custom2Selection ? formValue.maxCountCustom2 : undefined,
            minCountCustom3:
              !Helper.isEmpty(formValue.minCountCustom3) && formValue?.custom3Selection ? formValue.minCountCustom3 : undefined,
            maxCountCustom3:
              !Helper.isEmpty(formValue.maxCountCustom3) && formValue?.custom3Selection ? formValue.maxCountCustom3 : undefined,
            availablePeriod: formValue.availablePeriod ?? undefined,
            availablePeriodUnit: formValue.availablePeriodUnit ?? undefined,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : undefined,
            refundPeriod: formValue.priceType == 'no_cost' ? null : formValue.refundPeriod ?? undefined,
            refundPeriodUnit: formValue.priceType == 'no_cost' ? null : formValue.refundPeriodUnit ?? undefined,
            refundDeadlineAt:
              formValue.priceType == 'no_cost'
                ? null
                : formValue.refundDeadlineAt
                ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD')
                : undefined,
            cancelPolicies:
              this.hasReserveTickets() && formValue.cancellationPolicySettingStatus == 'T' ? payloadCancelPolicies : undefined,
            maxParticipantCount: formValue.salesLimitStatus ? null : +formValue.maxParticipantCount,
            isDisplayRemainingCount: formValue.salesLimitStatus ? false : formValue.isDisplayRemainingCount,
            voucherType: formValue.voucherType,
            fixedVoucherCode: formValue.voucherType == 'none' ? null : formValue.fixedVoucherCode,
            isDisplayAnonymousForm: formValue.isDisplayAnonymousForm
          };
          if (!Object.keys(subtitle).length) {
            delete payload.subtitle;
          }
          if (!Object.keys(note).length) {
            delete payload.note;
          }
          if (!Object.keys(custom1Name).length) {
            delete payload.custom1Name;
          }
          if (!Object.keys(custom1Note).length) {
            delete payload.custom1Note;
          }
          if (!Object.keys(custom2Name).length) {
            delete payload.custom2Name;
          }
          if (!Object.keys(custom2Note).length) {
            delete payload.custom2Note;
          }
          if (!Object.keys(custom3Name).length) {
            delete payload.custom3Name;
          }
          if (!Object.keys(custom3Note).length) {
            delete payload.custom3Note;
          }
          if (this.hasReserveTickets()) {
            delete payload.refundPeriod;
            delete payload.refundPeriodUnit;
            delete payload.refundDeadlineAt;
          }
          if (!this.hasNoReserveTickets()) {
            delete payload.availablePeriod;
            delete payload.availablePeriodUnit;
            delete payload.availableDeadlineAt;
          }
          //set start date
          const selectedStartTimeDup = new Date(formValue.saleStartAt);
          const startDateInfoEditorDup = {
            year: selectedStartTimeDup.getFullYear(),
            month: selectedStartTimeDup.getMonth(),
            day: selectedStartTimeDup.getDate(),
            hour: parseInt(this.saleStartAtTime.split(':')[0]),
            minute: parseInt(this.saleStartAtTime.split(':')[1]),
            second: 0
          };
          const momentStartObjectEditorDup = moment(startDateInfoEditorDup).format('YYYY-MM-DDTHH:mm:ss');
          payload[`saleStartAt`] = momentStartObjectEditorDup + this.timezone;

          //set end date
          if (!formValue.saleEndAt) {
            payload[`saleEndAt`] = undefined;
          } else {
            const selectedEndTime = new Date(formValue.saleEndAt);
            const endDateInfoEditor = {
              year: selectedEndTime.getFullYear(),
              month: selectedEndTime.getMonth(),
              day: selectedEndTime.getDate(),
              hour: parseInt(this.saleEndAtTime.split(':')[0]),
              minute: parseInt(this.saleEndAtTime.split(':')[1]),
              second: 0
            };
            const momentEndObjectEditor = moment(endDateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
            payload[`saleEndAt`] = momentEndObjectEditor + this.timezone;
          }
          this.ticketEditorService.postSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.isChooseDetailUpdate = false;
              this.deleteTitle();
              this.getListSaleTicket(data.saleTicketId as number, Constant.BOTTOM_ENUM);
              this.initFormSaleTicket();
              this.initFormCancellationFee();
              this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
              this.isDragDrop = false;
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleEditSetTicket:
          // Edit sales ticket
          payload = {
            saleTicketId: this.setTicketSelected.saleTicketId ?? null,
            isSale: formValue.isSale ? (formValue.isSale == 'true' ? true : false) : null,
            saleTicketName: saleTicketName,
            subtitle: Object.keys(subtitle).length ? subtitle : null,
            note: Object.keys(note).length ? note : null,
            saleStartAt: moment(formValue.saleStartAt).format('YYYY-MM-DD'),
            saleEndAt: formValue.saleEndAt ? moment(formValue.saleEndAt).format('YYYY-MM-DD') : null,
            custom1Name: Object.keys(custom1Name)?.length ? custom1Name : null,
            custom1Note: Object.keys(custom1Note)?.length ? custom1Note : null,
            custom2Name: Object.keys(custom2Name)?.length ? custom2Name : null,
            custom2Note: Object.keys(custom2Note)?.length ? custom2Note : null,
            custom3Name: Object.keys(custom3Name)?.length ? custom3Name : null,
            custom3Note: Object.keys(custom3Note)?.length ? custom3Note : null,
            maxParticipantCount: formValue.salesLimitStatus ? null : +formValue.maxParticipantCount,
            isDisplayRemainingCount: formValue.salesLimitStatus ? false : formValue.isDisplayRemainingCount,
            voucherType: formValue.voucherType,
            fixedVoucherCode: formValue.voucherType == 'none' ? null : formValue.fixedVoucherCode,
            isDisplayAnonymousForm: formValue.isDisplayAnonymousForm
          };

          if (formValue.cancellationPolicySettingStatus == 'T') {
            for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
              let formElement = {
                period: +formCancellationFeeValue[`period${index}`],
                periodUnit: formCancellationFeeValue[`periodUnit${index}`],
                cancellationFeePercentage: +formCancellationFeeValue[`cancellationFeePercentage${index}`]
              };
              payloadCancelPolicies.push(formElement);
            }
            payload.cancelPolicies = payloadCancelPolicies;
          }

          //set start date
          const selectedStartTimeEdit = new Date(formValue.saleStartAt);
          const startDateInfoEditorEdit = {
            year: selectedStartTimeEdit.getFullYear(),
            month: selectedStartTimeEdit.getMonth(),
            day: selectedStartTimeEdit.getDate(),
            hour: parseInt(this.saleStartAtTime.split(':')[0]),
            minute: parseInt(this.saleStartAtTime.split(':')[1]),
            second: 0
          };
          const momentStartObjectEditorEdit = moment(startDateInfoEditorEdit).format('YYYY-MM-DDTHH:mm:ss');
          payload[`saleStartAt`] = momentStartObjectEditorEdit + this.timezone;

          //set end date
          if (!formValue.saleEndAt) {
            payload[`saleEndAt`] = null;
          } else {
            const selectedEndTime = new Date(formValue.saleEndAt);
            const endDateInfoEditor = {
              year: selectedEndTime.getFullYear(),
              month: selectedEndTime.getMonth(),
              day: selectedEndTime.getDate(),
              hour: parseInt(this.saleEndAtTime.split(':')[0]),
              minute: parseInt(this.saleEndAtTime.split(':')[1]),
              second: 0
            };
            const momentEndObjectEditor = moment(endDateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
            payload[`saleEndAt`] = momentEndObjectEditor + this.timezone;
          }

          this.ticketEditorService.putSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.deleteTitle();
              this.getListSaleTicket(data.saleTicketId as number);
              this.initFormSaleTicket();
              this.initFormCancellationFee();
              this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
              this.isDragDrop = false;
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleAddReservation:
        case this.titleEditReservation:
          payload = {
            saleTicketId: this.setTicketSelected.saleTicketId,
            saleTicketName: saleTicketName,
            subtitle: Object.keys(subtitle).length ? subtitle : null,
            note: Object.keys(note).length ? note : null,
            priceAdult:
              formValue.priceType == 'no_cost' && formValue?.adultSelection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceAdult && formValue?.adultSelection
                ? formValue.priceAdult
                : undefined,
            custom1Name: Object.keys(custom1Name)?.length && this.formSaleTicket.value.custom1Selection ? custom1Name : null,
            custom1Note: Object.keys(custom1Note)?.length && this.formSaleTicket.value.custom1Selection ? custom1Note : null,
            custom2Name: Object.keys(custom2Name)?.length && this.formSaleTicket.value.custom2Selection ? custom2Name : null,
            custom2Note: Object.keys(custom2Note)?.length && this.formSaleTicket.value.custom2Selection ? custom2Note : null,
            custom3Name: Object.keys(custom3Name)?.length && this.formSaleTicket.value.custom3Selection ? custom3Name : null,
            custom3Note: Object.keys(custom3Note)?.length && this.formSaleTicket.value.custom3Selection ? custom3Note : null,
            priceCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom1 && formValue?.custom1Selection
                ? formValue.priceCustom1
                : undefined,
            priceCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom2 && formValue?.custom2Selection
                ? formValue.priceCustom2
                : undefined,
            priceCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? 0
                : formValue.priceType == 'fixed' && formValue.priceCustom3 && formValue?.custom3Selection
                ? formValue.priceCustom3
                : undefined,
            refundFeeAdult:
              formValue.priceType == 'no_cost' && formValue.adultSelection
                ? null
                : !Helper.isEmpty(formValue.refundFeeAdult) &&
                  formValue.adultSelection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeAdult
                : undefined,
            refundFeeCustom1:
              formValue.priceType == 'no_cost' && formValue?.custom1Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom1) &&
                  formValue?.custom1Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom1
                : undefined,
            refundFeeCustom2:
              formValue.priceType == 'no_cost' && formValue?.custom2Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom2) &&
                  formValue?.custom2Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom2
                : undefined,
            refundFeeCustom3:
              formValue.priceType == 'no_cost' && formValue?.custom3Selection
                ? null
                : !Helper.isEmpty(formValue.refundFeeCustom3) &&
                  formValue?.custom3Selection &&
                  formValue.refundFeeMethod == Constant.PRICE_CLASS_VALUE
                ? formValue.refundFeeCustom3
                : undefined,
            refundFeeTicket:
              formValue.priceType == 'no_cost'
                ? null
                : !Helper.isEmpty(formValue.refundFeeTicket) && formValue.refundFeeMethod == Constant.PER_TICKET_VALUE
                ? formValue.refundFeeTicket
                : undefined,
            minCountAdult:
              !Helper.isEmpty(formValue.minCountAdult) && this.formSaleTicket.value.adultSelection ? formValue.minCountAdult : null,
            maxCountAdult:
              !Helper.isEmpty(formValue.maxCountAdult) && this.formSaleTicket.value.adultSelection ? formValue.maxCountAdult : null,
            minCountCustom1:
              !Helper.isEmpty(formValue.minCountCustom1) && this.formSaleTicket.value.custom1Selection ? formValue.minCountCustom1 : null,
            maxCountCustom1:
              !Helper.isEmpty(formValue.maxCountCustom1) && this.formSaleTicket.value.custom1Selection ? formValue.maxCountCustom1 : null,
            minCountCustom2:
              !Helper.isEmpty(formValue.minCountCustom2) && this.formSaleTicket.value.custom2Selection ? formValue.minCountCustom2 : null,
            maxCountCustom2:
              !Helper.isEmpty(formValue.maxCountCustom2) && this.formSaleTicket.value.custom2Selection ? formValue.maxCountCustom2 : null,
            minCountCustom3:
              !Helper.isEmpty(formValue.minCountCustom3) && this.formSaleTicket.value.custom3Selection ? formValue.minCountCustom3 : null,
            maxCountCustom3:
              !Helper.isEmpty(formValue.maxCountCustom3) && this.formSaleTicket.value.custom3Selection ? formValue.maxCountCustom3 : null,
            availablePeriod: formValue.availablePeriod ?? null,
            availablePeriodUnit: formValue.availablePeriodUnit ?? null,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : null,
            refundPeriod: formValue.priceType == 'no_cost' ? null : formValue.refundPeriod ?? undefined,
            refundPeriodUnit: formValue.priceType == 'no_cost' ? null : formValue.refundPeriodUnit ?? undefined,
            refundDeadlineAt:
              formValue.priceType == 'no_cost'
                ? null
                : formValue.refundDeadlineAt
                ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD')
                : undefined,
            cancelPolicies:
              this.hasReserveTickets() && formValue.cancellationPolicySettingStatus == 'T' && this.ticketSetDetail.cancelPolicies
                ? this.ticketSetDetail.cancelPolicies
                : undefined,
            fixedVoucherCode: formValue.voucherType == 'none' ? null : formValue.fixedVoucherCode
          };
          if (this.hasReserveTickets()) {
            delete payload.refundPeriod;
            delete payload.refundPeriodUnit;
            delete payload.refundDeadlineAt;
          }
          if (!this.hasNoReserveTickets()) {
            delete payload.availablePeriod;
            delete payload.availablePeriodUnit;
            delete payload.availableDeadlineAt;
          }
          const selectedTime = new Date(formValue.updateScheduleAt);
          const dateInfoEditor = {
            year: selectedTime.getFullYear(),
            month: selectedTime.getMonth(),
            day: selectedTime.getDate(),
            hour: parseInt(this.updateScheduleAtTime.split(':')[0]),
            minute: parseInt(this.updateScheduleAtTime.split(':')[1]),
            second: 0
          };
          const momentObjectEditor = moment(dateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
          payload[`updateScheduleAt`] = momentObjectEditor + this.timezone;
          this.ticketEditorService.postSaleTicketUpdate(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.deleteTitle();
              this.getListSaleTicket(data.saleTicketId as number);
              this.initFormSaleTicket();
              this.initFormCancellationFee();
              this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
              this.isDragDrop = false;
              this.isChooseDetailUpdate = true;
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              this.formSaleTicket.get('appId').clearValidators();
              return;
            }
          );
          break;
      }
    }
  }

  /**
   * fillValueToForm
   * @param ticketSetDetail
   */
  fillValueToForm(ticketSetDetail: SaleTicketDetail): void {
    let formValues: any = {
      saleTicketId: ticketSetDetail?.saleTicketId,
      appId: ticketSetDetail?.appId,
      appName: ticketSetDetail?.appName,
      companyId: ticketSetDetail?.companyId,
      saleStartAt: ticketSetDetail?.saleStartAt,
      isSale: ticketSetDetail?.isSale ? 'true' : 'false',
      currencyUnit: ticketSetDetail?.currencyUnit,
      priceType: ticketSetDetail?.priceType,
      adultSelection: !Helper.isEmpty(ticketSetDetail?.minCountAdult) ? true : false,
      custom1Selection: !Helper.isEmpty(ticketSetDetail?.minCountCustom1) ? true : false,
      custom2Selection: !Helper.isEmpty(ticketSetDetail?.minCountCustom2) ? true : false,
      custom3Selection: !Helper.isEmpty(ticketSetDetail?.minCountCustom3) ? true : false,
      priceAdult: ticketSetDetail.priceType == Constant.FREE_TICKETS ? null : ticketSetDetail?.priceAdult,
      priceCustom1: ticketSetDetail.priceType == Constant.FREE_TICKETS ? null : ticketSetDetail?.priceCustom1,
      priceCustom2: ticketSetDetail.priceType == Constant.FREE_TICKETS ? null : ticketSetDetail?.priceCustom2,
      priceCustom3: ticketSetDetail.priceType == Constant.FREE_TICKETS ? null : ticketSetDetail?.priceCustom3,
      refundFeeMethod: !Helper.isEmpty(ticketSetDetail?.refundFeeTicket) ? '2' : '1',
      refundFeeAdult: ticketSetDetail?.refundFeeAdult,
      refundFeeCustom1: ticketSetDetail?.refundFeeCustom1,
      refundFeeCustom2: ticketSetDetail?.refundFeeCustom2,
      refundFeeCustom3: ticketSetDetail?.refundFeeCustom3,
      refundFeeTicket: ticketSetDetail?.refundFeeTicket,
      minCountAdult: ticketSetDetail?.minCountAdult,
      maxCountAdult: ticketSetDetail?.maxCountAdult,
      minCountCustom1: ticketSetDetail?.minCountCustom1,
      maxCountCustom1: ticketSetDetail?.maxCountCustom1,
      minCountCustom2: ticketSetDetail?.minCountCustom2,
      maxCountCustom2: ticketSetDetail?.maxCountCustom2,
      minCountCustom3: ticketSetDetail?.minCountCustom3,
      maxCountCustom3: ticketSetDetail?.maxCountCustom3,
      availablePeriod: ticketSetDetail?.availablePeriod,
      availablePeriodUnit: ticketSetDetail?.availablePeriodUnit,
      refundPeriod: ticketSetDetail?.refundPeriod,
      refundPeriodUnit: ticketSetDetail?.refundPeriodUnit,
      salesLimitStatus: ticketSetDetail?.maxParticipantCount ? false : true,
      maxParticipantCount: ticketSetDetail?.maxParticipantCount,
      isDisplayRemainingCount: ticketSetDetail?.isDisplayRemainingCount,
      voucherType: ticketSetDetail?.voucherType,
      fixedVoucherCode: ticketSetDetail?.fixedVoucherCode ? ticketSetDetail?.fixedVoucherCode : this.generateRandomCode(),
      isDisplayAnonymousForm: ticketSetDetail?.isDisplayAnonymousForm
    };
    this.languages.forEach(element => {
      formValues[`saleTicketName${element}`] = ticketSetDetail?.saleTicketName?.[element] ?? '';
      formValues[`companyName${element}`] = ticketSetDetail?.companyName?.[element] ?? '';
      formValues[`subtitle${element}`] = ticketSetDetail?.subtitle?.[element] ?? '';
      formValues[`note${element}`] = ticketSetDetail?.note?.[element] ?? '';
      formValues[`custom1Name${element}`] = ticketSetDetail?.custom1Name?.[element] ?? '';
      formValues[`custom2Name${element}`] = ticketSetDetail?.custom2Name?.[element] ?? '';
      formValues[`custom3Name${element}`] = ticketSetDetail?.custom3Name?.[element] ?? '';
      formValues[`custom1Note${element}`] = ticketSetDetail?.custom1Note?.[element] ?? '';
      formValues[`custom2Note${element}`] = ticketSetDetail?.custom2Note?.[element] ?? '';
      formValues[`custom3Note${element}`] = ticketSetDetail?.custom3Note?.[element] ?? '';
    });
    this.formSaleTicket.patchValue(formValues);
    if (ticketSetDetail?.updateScheduleAt) {
      this.formSaleTicket.patchValue({
        updateScheduleAt: ticketSetDetail?.updateScheduleAt
      });
    }
    if (ticketSetDetail?.saleEndAt) {
      this.formSaleTicket.patchValue({
        saleEndAt: ticketSetDetail?.saleEndAt
      });
    }
    if (ticketSetDetail?.availableDeadlineAt) {
      this.formSaleTicket.patchValue({
        availableDeadlineAt: ticketSetDetail?.availableDeadlineAt
      });
    }
    if (ticketSetDetail?.refundDeadlineAt) {
      this.formSaleTicket.patchValue({
        refundDeadlineAt: ticketSetDetail?.refundDeadlineAt
      });
    }
    if (this.formSaleTicket.value.adultSelection) {
      this.checkChangeCategory('0', true);
      if (ticketSetDetail.priceType == 'fixed') {
        this.validateOptinalFieds('priceAdult', null, null, 50, 999999, true);
      }
      this.validateOptinalFieds('minCountAdult', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountAdult', null, null, 0, 9, true);
    } else {
      this.checkChangeCategory('0', true);
    }
    if (this.formSaleTicket.value.custom1Selection) {
      this.checkChangeCategory('1', true);
      this.languages.forEach(element => {
        if (element == 'ja') {
          this.validateOptinalFieds('custom1Name' + element, 1, 4, null, null, true, true);
        } else {
          this.validateOptinalFieds('custom1Name' + element, 1, 25, null, null, true, true);
        }
      });
      if (ticketSetDetail.priceType == 'fixed') {
        this.validateOptinalFieds('priceCustom1', null, null, 50, 999999, true);
      }
      this.validateOptinalFieds('minCountCustom1', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom1', null, null, 0, 9, true);
    } else {
      this.checkChangeCategory('1', true);
    }
    if (this.formSaleTicket.value.custom2Selection) {
      this.checkChangeCategory('2', true);
      this.languages.forEach(element => {
        if (element == 'ja') {
          this.validateOptinalFieds('custom2Name' + element, 1, 4, null, null, true, true);
        } else {
          this.validateOptinalFieds('custom2Name' + element, 1, 25, null, null, true, true);
        }
      });
      if (ticketSetDetail.priceType == 'fixed') {
        this.validateOptinalFieds('priceCustom2', null, null, 50, 999999, true);
      }
      this.validateOptinalFieds('minCountCustom2', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom2', null, null, 0, 9, true);
    } else {
      this.checkChangeCategory('2', true);
    }
    if (this.formSaleTicket.value.custom3Selection) {
      this.checkChangeCategory('3', true);
      this.languages.forEach(element => {
        if (element == 'ja') {
          this.validateOptinalFieds('custom3Name' + element, 1, 4, null, null, true, true);
        } else {
          this.validateOptinalFieds('custom3Name' + element, 1, 25, null, null, true, true);
        }
      });
      if (ticketSetDetail.priceType == 'fixed') {
        this.validateOptinalFieds('priceCustom3', null, null, 50, 999999, true);
      }
      this.validateOptinalFieds('minCountCustom3', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom3', null, null, 0, 9, true);
    } else {
      this.checkChangeCategory('3', true);
    }
    if (this.ticketSetDetail?.maxParticipantCount) {
      this.setValidatorsAndDoNotChangeTheValue('maxParticipantCount', [Validators.required, Validators.min(1), Validators.max(99999)]);
    }
    if (this.ticketSetDetail?.voucherType == Constant.FIXED_FIRST) {
      this.formSaleTicket.get('fixedVoucherCode').setValidators([Validators.required, this.eightCharactersValidator()]);
      this.formSaleTicket.get('fixedVoucherCode').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * Cancel edit detail set ticket
   */
  cancelDetailSaleTicket(): void {
    this.languages = _.cloneDeep([this.languageSelected]);
    this.isEditSaleTicketDetail = this.setTicketSelected ? false : undefined;
    this.isDragDrop = false;
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
    this.initFormSaleTicket();
    this.initFormCancellationFee();
    if (this.setTicketSelected) {
      this.getDetailTicketSet(this.setTicketSelected.saleTicketId, null);
      this.ticketSetDetail.combinationTicket = this.combinationTicketClone;
      this.ticketSetDetail.isDisplayInformationTicketName = false;
      this.ticketSetDetail.isDisplayInformationCompanyName = false;
      this.ticketSetDetail.isDisplayInformationSubtitle = false;
      this.ticketSetDetail.isDisplayInformationNote = false;
      this.ticketSetDetail.isDisplayInformationNote1 = false;
      this.ticketSetDetail.isDisplayInformationNote2 = false;
      this.ticketSetDetail.isDisplayInformationNote3 = false;
      this.ticketSetDetail.isDisplayInformationSalesName1 = false;
      this.ticketSetDetail.isDisplayInformationSalesName2 = false;
      this.ticketSetDetail.isDisplayInformationSalesName3 = false;
    } else {
      this.ticketSetDetail = undefined;
      this.listCombinationTicket = [];
      this.listCombinationTicketOrigin = [];
    }
    this.isEditType = false;
    this.deleteTitle();
  }

  /**
   * deleteTitle
   */
  deleteTitle() {
    this.titleFormData = '';
    this.titleEditSetTicket = undefined;
    this.titleEditReservation = undefined;
    this.titleAddReservation = undefined;
    this.titleNewRegistrationSetTicket = undefined;
    this.titleDuplicateSetTicket = undefined;
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * Get title item
   * @param msg
   * @param param
   * @param param2
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * Set height sale ticket detail
   */
  setHeightSaleTicketDetail(): string {
    const heightOrderSaleTicket = 400; // Height order set ticket
    const heightOrderSaleTicketEdit = 760; // Height order set ticket edit
    const heightRowTable = 26;
    const maxHeightRowTable = 156;
    const countOrderTicket = this.ticketSetDetail['combinationTicket'] ? this.ticketSetDetail['combinationTicket'].length : 0;
    const maxHeight = this.isEditSaleTicketDetail ? heightOrderSaleTicketEdit - heightRowTable : heightOrderSaleTicket;

    let height = maxHeight - maxHeightRowTable;
    if (!this.isEditSaleTicketDetail) {
      if (countOrderTicket == 0) {
        height = maxHeight;
      } else if (countOrderTicket < 5) {
        height = maxHeight - heightRowTable * (countOrderTicket + 1);
      }
    } else {
      if (countOrderTicket < 3) {
        height = maxHeight - heightRowTable * (countOrderTicket + 1);
        if (this.isDragDrop) {
          height = height - 56;
        }
      }
    }

    return `${height}px`;
  }

  /**
   * Set Date
   * @param event
   * @param itemName
   */
  setDate(event: any, itemName: string): void {
    if (event) {
      const date = new Date(event);
      this.formSaleTicket.get(itemName).patchValue(date);
      this.ticketSetDetail[`isClear${itemName}`] = false;
      if (['availableDeadlineAt', 'refundDeadlineAt'].includes(itemName)) {
        this.formSaleTicket.get('saleEndAt').setValidators(Validators.required);
        this.formSaleTicket.get('saleEndAt').updateValueAndValidity();
      }
    } else {
      this.formSaleTicket.get(itemName).patchValue('');
    }
  }

  /**
   * setTime
   * @param event
   * @param itemName
   * @returns
   */
  setTime(event: any, itemName: string) {
    if (!event) {
      return;
    }
    this[itemName] = event;
    this.ticketSetDetail[`isClear${itemName}`] = false;
  }

  /**
   * initFormSaleTicket
   */
  initFormSaleTicket(): void {
    this.formSaleTicket = this.formBuilder.group({
      saleTicketId: [],
      updateScheduleAt: [],
      appId: [, Validators.required],
      appName: [],
      companyId: [],
      operatorId: [],
      saleStartAt: [, [Validators.required]],
      saleEndAt: [],
      isSale: [],
      currencyUnit: ['JPY', [Validators.required]],
      priceType: ['fixed', [Validators.required]],
      adultSelection: [],
      custom1Selection: [],
      custom2Selection: [],
      custom3Selection: [],
      refundFeeMethod: ['1', [Validators.required]],
      priceAdult: [],
      priceCustom1: [],
      priceCustom2: [],
      priceCustom3: [],
      refundFeeAdult: [],
      refundFeeCustom1: [],
      refundFeeCustom2: [],
      refundFeeCustom3: [],
      refundFeeTicket: [],
      minCountAdult: [],
      maxCountAdult: [],
      minCountCustom1: [],
      maxCountCustom1: [],
      minCountCustom2: [],
      maxCountCustom2: [],
      minCountCustom3: [],
      maxCountCustom3: [],
      availablePeriod: [],
      availablePeriodUnit: [],
      availableDeadlineAt: [],
      refundPeriod: [],
      refundPeriodUnit: [],
      refundDeadlineAt: [],
      cancellationPolicySettingStatus: [],
      salesLimitStatus: [true, [Validators.required]],
      maxParticipantCount: [],
      isDisplayRemainingCount: [false],
      voucherType: ['none', [Validators.required]],
      fixedVoucherCode: [this.generateRandomCode()],
      isDisplayAnonymousForm: [false]
    });
  }

  /**
   * noWhitespaceValidator
   * @param control
   * @returns
   */
  public noWhitespaceValidator(control: FormControl) {
    return String(control.value || '').trim().length ? null : { whitespace: true };
  }

  /**
   * decimalValidator
   * @returns
   */
  decimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\d+$/.test(value)) {
        return { decimal: true };
      }
      return null;
    };
  }

  /**
   * validateOptinalFieds
   * @param fileControlname
   * @param minLength
   * @param maxLength
   * @param minValue
   * @param maxValue
   */
  validateOptinalFieds(
    fileControlname: string,
    minLength: number,
    maxLength: number,
    minValue: number,
    maxValue: number,
    isFixing?: boolean,
    isEdit?: boolean
  ): void {
    const listControl = ['availablePeriod', 'refundPeriod', 'availablePeriodUnit', 'refundPeriodUnit'];
    let fileControl = this.formSaleTicket.get(fileControlname);
    if (!isFixing) {
      if (listControl.includes(fileControlname)) {
        if (fileControl?.value == null || fileControl?.value == undefined || fileControl?.value == '') {
          if (fileControlname == 'availablePeriod') {
            this.formSaleTicket.get('availablePeriodUnit').clearValidators();
            this.formSaleTicket.get('availablePeriodUnit').updateValueAndValidity();
          } else if (fileControlname == 'refundPeriod') {
            this.formSaleTicket.get('refundPeriodUnit').clearValidators();
            this.formSaleTicket.get('refundPeriodUnit').updateValueAndValidity();
          } else if (fileControlname == 'availablePeriodUnit') {
            this.formSaleTicket.get('availablePeriod').setValidators([Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('availablePeriod').updateValueAndValidity();
          } else if (fileControlname == 'refundPeriodUnit') {
            this.formSaleTicket.get('refundPeriod').setValidators([Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('refundPeriod').updateValueAndValidity();
          }
          if (this.formSaleTicket.get('availablePeriod')?.value) {
            this.formSaleTicket.get('availablePeriodUnit').setValidators(Validators.required);
            this.formSaleTicket.get('availablePeriodUnit').updateValueAndValidity();
          }
          if (this.formSaleTicket.get('refundPeriod')?.value) {
            this.formSaleTicket.get('refundPeriodUnit').setValidators(Validators.required);
            this.formSaleTicket.get('refundPeriodUnit').updateValueAndValidity();
          }
          if (this.formSaleTicket.get('availablePeriodUnit')?.value) {
            this.formSaleTicket
              .get('availablePeriod')
              .setValidators([Validators.required, Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('availablePeriod').updateValueAndValidity();
          }
          if (this.formSaleTicket.get('refundPeriodUnit')?.value) {
            this.formSaleTicket
              .get('refundPeriod')
              .setValidators([Validators.required, Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('refundPeriod').updateValueAndValidity();
          }
        } else {
          if (fileControlname == 'availablePeriod') {
            if (minValue !== null && maxValue !== null) {
              fileControl.setValidators([Validators.min(minValue), Validators.max(maxValue), this.decimalValidator()]);
              fileControl?.updateValueAndValidity();
            }
            this.formSaleTicket.get('availablePeriodUnit').setValidators(Validators.required);
            this.formSaleTicket.get('availablePeriodUnit').updateValueAndValidity();
          } else if (fileControlname == 'refundPeriod') {
            if (minValue !== null && maxValue !== null) {
              fileControl.setValidators([Validators.min(minValue), Validators.max(maxValue), this.decimalValidator()]);
              fileControl?.updateValueAndValidity();
            }
            this.formSaleTicket.get('refundPeriodUnit').setValidators(Validators.required);
            this.formSaleTicket.get('refundPeriodUnit').updateValueAndValidity();
          } else if (fileControlname == 'availablePeriodUnit') {
            this.formSaleTicket
              .get('availablePeriod')
              .setValidators([Validators.required, Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('availablePeriod').updateValueAndValidity();
          } else if (fileControlname == 'refundPeriodUnit') {
            this.formSaleTicket
              .get('refundPeriod')
              .setValidators([Validators.required, Validators.min(1), Validators.max(365), this.decimalValidator()]);
            this.formSaleTicket.get('refundPeriod').updateValueAndValidity();
          }
        }
      } else {
        if (
          fileControl?.value == null ||
          fileControl?.value == undefined ||
          (fileControl?.value && typeof fileControl?.value === 'string' && fileControl?.value?.trim().length === 0)
        ) {
          fileControl.clearValidators();
          fileControl.updateValueAndValidity();
        } else {
          if (minLength && maxLength) {
            fileControl.setValidators([Validators.minLength(minLength), Validators.maxLength(maxLength)]);
            fileControl?.updateValueAndValidity();
          }
          if (minValue !== null && maxValue !== null) {
            fileControl.setValidators([Validators.min(minValue), Validators.max(maxValue), this.decimalValidator()]);
            fileControl?.updateValueAndValidity();
          }
        }
      }
    } else {
      const currentValidators = this.formSaleTicket.get(fileControlname).validator;
      if (currentValidators) {
        if (minLength && maxLength) {
          fileControl.setValidators([currentValidators, Validators.minLength(minLength), Validators.maxLength(maxLength)]);
          fileControl?.updateValueAndValidity();
        }
        if (minValue !== null && maxValue !== null) {
          fileControl.setValidators([currentValidators, Validators.min(minValue), Validators.max(maxValue), this.decimalValidator()]);
          fileControl?.updateValueAndValidity();
        }
      } else {
        if (minLength && maxLength) {
          fileControl.setValidators([Validators.minLength(minLength), Validators.maxLength(maxLength)]);
          fileControl?.updateValueAndValidity();
        }
        if (minValue !== null && maxValue !== null) {
          fileControl.setValidators([Validators.min(minValue), Validators.max(maxValue), this.decimalValidator()]);
          fileControl?.updateValueAndValidity();
        }
      }
    }
    if (isEdit) {
      fileControl.markAsUntouched();
      fileControl.markAsPristine();
    } else {
      fileControl.markAllAsTouched();
    }
  }

  /**
   * markTheElement
   * @param formGroup
   */
  markTheElement(formGroup: FormGroup, formName: string): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.markTheElement(control, formName);
      } else if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    this.emitInputEvent(formName);
  }

  /**
   * emitInputEvent
   */
  emitInputEvent(formName: string): void {
    if (formName === 'CancellationFeeForm') {
      this.formInputFormCancellationFee.forEach(input => {
        const event = new Event('input', { bubbles: true });
        this.renderer.selectRootElement(input.nativeElement).dispatchEvent(event);
      });
    } else if (formName === 'SaleTicketForm') {
      this.formInputs.forEach(input => {
        const event = new Event('input', { bubbles: true });
        this.renderer.selectRootElement(input.nativeElement).dispatchEvent(event);
      });
    }
  }

  /**
   * get form Control
   */
  get f() {
    return this.formSaleTicket.controls;
  }

  /**
   * get form Control  Cancellation Fee
   */
  get formCancelFee() {
    return this.formCancellationFee.controls;
  }

  /**
   * getSalesCondition
   * @param value
   * @returns
   */
  getSalesCondition(value: string): string {
    const salesCondition =
      value == 'red'
        ? this.translateService.instant('ticket-editor.combination.sale')
        : this.translateService.instant('ticket-editor.combination.pause');
    return salesCondition ? salesCondition : '';
  }

  /**
   * getTimeUnit
   * @param value
   * @returns
   */
  getTimeUnit(value: string): string {
    if (!value) {
      return;
    } else if (value == 'D') {
      return this.translateService.instant('ticket-editor.combination.day');
    } else if (value == 'M') {
      return this.translateService.instant('ticket-editor.combination.month');
    }
  }

  /**
   * getFixedPrice
   * @param value
   * @returns
   */
  getFixedPrice(value: string): string {
    const fixedPrice =
      value == 'Fixed'
        ? this.translateService.instant('ticket-editor.combination.fixed')
        : this.translateService.instant('ticket-editor.combination.fluctuating');
    return fixedPrice ? fixedPrice : '';
  }

  /**
   * validateRequiredTime
   * @param fileControlname
   * @param dependentFileHandleName
   */
  validateRequiredTime(fileControlname: string, dependentFileHandleName: string): void {
    let fileControl = this.formSaleTicket.get(fileControlname);
    let dependentFileControl = this.formSaleTicket.get(dependentFileHandleName);
    if (fileControl?.value || (fileControl?.value && typeof fileControl?.value === 'string' && fileControl?.value?.trim().length > 0)) {
      dependentFileControl.setValidators([Validators.required]);
      dependentFileControl?.updateValueAndValidity();
    } else {
      dependentFileControl.clearValidators();
      dependentFileControl.updateValueAndValidity();
    }
  }

  /**
   *
   * @param property
   */
  displayInformation(property: string): void {
    this.ticketSetDetail[`${property}`] = !this.ticketSetDetail[`${property}`];
  }

  /**
   * onDrop
   * @param event
   */
  onDrop(event: CdkDragDrop<any[]>): void {
    if (!this.isDragDrop) {
      return;
    }
    if (event.previousContainer !== event.container) {
      // Ensure we are dropping into the destination list (list-connect)
      this.draggedItem = _.cloneDeep(event.item.data);
      //this.draggedItem.ticketId = this.setTicketSelected.saleTicketId;
      if (!this.ticketSetDetail.combinationTicket && event.container.id === 'list-connect') {
        this.ticketSetDetail.combinationTicket = new Array<CombinationTicket>();
        transferArrayItem([this.draggedItem], this.ticketSetDetail.combinationTicket, 0, event.currentIndex);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
        this.ticketSetDetail.combinationTicket[0].displayPriority = 1;
      } else if (event.container.id === 'list-connect') {
        transferArrayItem([this.draggedItem], this.ticketSetDetail.combinationTicket, 0, event.currentIndex);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
        const index = this.ticketSetDetail.combinationTicket.findIndex(e => !e.displayPriority);
        if (index != -1) {
          this.ticketSetDetail.combinationTicket[index].displayPriority = this.ticketSetDetail.combinationTicket.length;
          this.ticketSetDetail.combinationTicket.sort((a, b) => a.displayPriority - b.displayPriority);
        }
      }
    }
    if (this.hasReserveTickets()) {
      this.formSaleTicket.get('refundPeriod').clearValidators();
      this.formSaleTicket.get('refundPeriod').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('refundPeriodUnit').clearValidators();
      this.formSaleTicket.get('refundPeriodUnit').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.patchValue({
        priceType: 'addition'
      });
      let oldValue;
      if (this.formSaleTicket.value.cancellationPolicySettingStatus) {
        oldValue = this.formSaleTicket.value.cancellationPolicySettingStatus;
      }
      this.formSaleTicket.get('cancellationPolicySettingStatus').setValidators(Validators.required);
      this.formSaleTicket.get('cancellationPolicySettingStatus').updateValueAndValidity;
      if (oldValue) {
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: oldValue
        });
      } else
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'T'
        });
      this.listCombinationTicket = this.listCombinationTicket.filter(e => !e.isReserve);
    }
    if (!this.hasNoReserveTickets()) {
      this.formSaleTicket.get('availablePeriod').clearValidators();
      this.formSaleTicket.get('availablePeriod').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('availablePeriodUnit').clearValidators();
      this.formSaleTicket.get('availablePeriodUnit').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
    this.validatePriceCategories();
  }

  /**
   * deleteTicket
   * @param ticket
   */
  deleteTicket(ticket: any) {
    const index = this.ticketSetDetail.combinationTicket.indexOf(ticket);
    if (index !== -1) {
      this.ticketSetDetail.combinationTicket.splice(index, 1);
      for (let i = index; i < this.ticketSetDetail.combinationTicket.length; i++) {
        this.ticketSetDetail.combinationTicket[i].displayPriority = i + 1;
      }
      this.ticketSetDetail.combinationTicket.sort((a, b) => a.displayPriority - b.displayPriority);
      if (this.hasReserveTickets()) {
        this.formSaleTicket.get('refundPeriod').clearValidators();
        this.formSaleTicket.get('refundPeriod').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.formSaleTicket.get('refundPeriodUnit').clearValidators();
        this.formSaleTicket.get('refundPeriodUnit').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.formSaleTicket.patchValue({
          priceType: 'addition'
        });
        let oldValue;
        if (this.formSaleTicket.value.cancellationPolicySettingStatus) {
          oldValue = this.formSaleTicket.value.cancellationPolicySettingStatus;
        }
        this.formSaleTicket.get('cancellationPolicySettingStatus').setValidators(Validators.required);
        this.formSaleTicket.get('cancellationPolicySettingStatus').updateValueAndValidity;
        if (oldValue) {
          this.formSaleTicket.patchValue({
            cancellationPolicySettingStatus: oldValue
          });
        } else {
          this.formSaleTicket.patchValue({
            cancellationPolicySettingStatus: 'T'
          });
        }
      } else {
        this.formSaleTicket.get('cancellationPolicySettingStatus').clearValidators();
        this.formSaleTicket.get('cancellationPolicySettingStatus').updateValueAndValidity();
        this.listCombinationTicket = _.cloneDeep(this.listCombinationTicketOrigin);
      }
      if (!this.hasNoReserveTickets()) {
        this.formSaleTicket.get('availablePeriod').clearValidators();
        this.formSaleTicket.get('availablePeriod').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.formSaleTicket.get('availablePeriodUnit').clearValidators();
        this.formSaleTicket.get('availablePeriodUnit').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      this.validatePriceCategories();
    }
  }

  /**
   * validateDrop
   * @param drag
   * @param drop
   * @returns
   */
  validateDrop = (drag: CdkDrag, drop: CdkDropList) => {
    return drop.id === 'list-connect';
  };

  /**
   * reorderRows
   * @param event
   * @returns
   */
  reorderRows(event: CdkDragDrop<any[]>) {
    if (!this.isEditSaleTicketDetail) {
      return;
    }
    if (event.isPointerOverContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      for (let i = 0; i < this.ticketSetDetail.combinationTicket.length; i++) {
        this.ticketSetDetail.combinationTicket[i].displayPriority = i + 1;
      }
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const index = this.ticketSetDetail.combinationTicket.indexOf(event.item.data);
      if (index !== -1) {
        this.ticketSetDetail.combinationTicket.splice(index, 1);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
        for (let i = 0; i < this.ticketSetDetail.combinationTicket.length; i++) {
          this.ticketSetDetail.combinationTicket[i].displayPriority = i + 1;
        }
        if (this.hasReserveTickets()) {
          this.formSaleTicket.patchValue({
            priceType: 'addition'
          });
          let oldValue;
          if (this.formSaleTicket.value.cancellationPolicySettingStatus) {
            oldValue = this.formSaleTicket.value.cancellationPolicySettingStatus;
          }
          this.formSaleTicket.get('cancellationPolicySettingStatus').setValidators(Validators.required);
          this.formSaleTicket.get('cancellationPolicySettingStatus').updateValueAndValidity;
          if (oldValue) {
            this.formSaleTicket.patchValue({
              cancellationPolicySettingStatus: oldValue
            });
          } else {
            this.formSaleTicket.patchValue({
              cancellationPolicySettingStatus: 'T'
            });
          }
        } else {
          this.listCombinationTicket = _.cloneDeep(this.listCombinationTicketOrigin);
          this.formSaleTicket.get('cancellationPolicySettingStatus').clearValidators();
          this.formSaleTicket.get('cancellationPolicySettingStatus').updateValueAndValidity();
        }
        this.validatePriceCategories();
      }
    }
  }

  /**
   * changeDetailUpdate
   * @param status
   */
  changeDetailUpdate(status: boolean): void {
    if (!this.setTicketSelected) {
      return;
    }
    this.isChooseDetailUpdate = status;
    this.isChooseDetailUpdate
      ? (this.ticketSetDetail = this.setTicketSelected.saleTicketDetailReserved)
      : (this.ticketSetDetail = this.setTicketSelected.saleTicketDetail);
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    if (this.isChooseDetailUpdate) {
      this.formSaleTicket.get('updateScheduleAt').setValidators(Validators.required);
      this.formSaleTicket.get('updateScheduleAt').updateValueAndValidity();
      this.formSaleTicket.get('saleStartAt').clearValidators();
      this.formSaleTicket.get('saleStartAt').updateValueAndValidity();
      this.formSaleTicket.get('saleEndAt').clearValidators();
      this.formSaleTicket.get('saleEndAt').updateValueAndValidity();
    }
  }

  /**
   * convertDate
   * @param date
   * @returns
   */
  convertDate(date: string) {
    if (date) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7)) - 1;
      const day = parseInt(date.substring(8, 10));
      const newDate = moment({ year, month, day }).toString();
      return new Date(newDate);
    }
  }

  /**
   * addSaleTicketUpdate
   */
  async addSaleTicketUpdate(): Promise<void> {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
    this.titleAddReservation = this.titleFormData;
    this.changeDetailUpdate(true);
    const data = _.cloneDeep(this.setTicketSelected.saleTicketDetail);
    data.isDisplayInformationTicketName = true;
    data.isDisplayInformationCompanyName = true;
    data.isDisplayInformationSubtitle = true;
    data.isDisplayInformationNote = true;
    data.isDisplayInformationNote1 = true;
    data.isDisplayInformationNote2 = true;
    data.isDisplayInformationNote3 = true;
    data.isDisplayInformationSalesName1 = true;
    data.isDisplayInformationSalesName2 = true;
    data.isDisplayInformationSalesName3 = true;
    data.isDisplayCancellationCharge = true;
    await [(this.ticketSetDetail = data)];

    let indexApp = this.listApp?.findIndex(e => e.appId == this.setTicketSelected.saleTicketDetail.appId);
    this.languages = [];
    if (indexApp !== -1) {
      this.languages = Constant.LANGUAGES_SETTING.filter(e =>
        this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
      )?.map(e => e.translation_language_code);
      this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
      this.languages = this.reorderLanguages(this.languages, this.languageSelected);
    }
    this.deleteMultilingualFields();
    this.updateMultilingualFields(this.languages);
    this.languages.forEach(element => {
      this.formSaleTicket.get(`companyName${element}`).disable();
    });

    if (this.hasReserveTickets()) {
      this.listCombinationTicket = _.cloneDeep(this.listCombinationTicket.filter(e => !e.isReserve));
      if (!this.ticketSetDetail?.cancelPolicies?.length) {
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'F'
        });
        this.checkChangeCancellationPolicySettingStatus('F');
      } else {
        this.deleteAllValidateCancelFee();
        for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
          const policy = this.ticketSetDetail.cancelPolicies[index];
          if (index == 0) {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit));
          } else {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period, [this.noWhitespaceValidator]));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit, [Validators.required]));
          }
          this.formCancellationFee.addControl(
            `cancellationFeePercentage${index}`,
            this.formBuilder.control(policy.cancellationFeePercentage, [Validators.required])
          );
        }
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'T'
        });
        this.checkChangeCancellationPolicySettingStatus('T');
      }
    }
    this.formSaleTicket.get('appId').disable();
    this.fillValueToForm(this.ticketSetDetail);
    // Select row set ticket
    this.isEditSaleTicketDetail = true;
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    // this.createFormValidate();
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * editSaleTicketUpdate
   * @returns
   */
  editSaleTicketUpdate() {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
    this.titleEditReservation = this.titleFormData;
    this.isEditType = true;
    this.changeDetailUpdate(true);
    this.isEditSaleTicketDetail = true;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationCompanyName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    this.ticketSetDetail.isDisplayCancellationCharge = true;
    let indexApp = this.listApp?.findIndex(e => e.appId == this.ticketSetDetail.appId);
    this.languages = [];
    if (indexApp !== -1) {
      this.languages = Constant.LANGUAGES_SETTING.filter(e =>
        this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
      )?.map(e => e.translation_language_code);
      this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
      this.languages = this.reorderLanguages(this.languages, this.languageSelected);
    }
    this.deleteMultilingualFields();
    this.updateMultilingualFields(this.languages);
    this.languages.forEach(element => {
      this.formSaleTicket.get(`companyName${element}`).disable();
    });

    if (this.hasReserveTickets()) {
      this.listCombinationTicket = _.cloneDeep(this.listCombinationTicket.filter(e => !e.isReserve));
      if (!this.ticketSetDetail?.cancelPolicies?.length) {
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'F'
        });
        this.checkChangeCancellationPolicySettingStatus('F');
      } else {
        this.deleteAllValidateCancelFee();
        for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
          const policy = this.ticketSetDetail.cancelPolicies[index];
          if (index == 0) {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit));
          } else {
            this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period, [this.noWhitespaceValidator]));
            this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit, [Validators.required]));
          }
          this.formCancellationFee.addControl(
            `cancellationFeePercentage${index}`,
            this.formBuilder.control(policy.cancellationFeePercentage, [Validators.required])
          );
        }
        this.formSaleTicket.patchValue({
          cancellationPolicySettingStatus: 'T'
        });
        this.checkChangeCancellationPolicySettingStatus('T');
      }
    }
    this.formSaleTicket.get('appId').disable();
    this.fillValueToForm(this.ticketSetDetail);
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = 0;
    }
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * deleteSaleTicketUpdate
   */
  deleteSaleTicketUpdate() {
    if (!this.listSaleTicket.length || !this.setTicketSelected || !this.setTicketSelected.saleTicketDetailReserved.updateScheduleAt) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.combination.confirm-delete-checked-set-ticket-update'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no'),
          title: this.translateService.instant('ticket-editor.tilte-delete-update')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payload = {
          saleTicketId: this.setTicketSelected.saleTicketId
        };
        this.ticketEditorService.deleteSaleTicketUpdate(this.informationAccount, payload).subscribe(
          (data: any) => {
            const saleTicketId = data.saleTicketId;
            this.getListSaleTicket(saleTicketId);
            this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, true]);
          },
          (error: any) => {
            return;
          }
        );
      }
    );
  }

  /**
   * translateTicket
   */
  translateTicket(): void {
    this.dialogService.showDialog(DialogTranslateTicketComponent, {}, result => {
      this.languages = result;
    });
  }

  /**
   * changeTitleFormData
   */
  changeTitleFormData() {
    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleNewRegistrationSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.new-registration-set-ticket');
          this.titleNewRegistrationSetTicket = this.titleFormData;
          break;
        case this.titleDuplicateSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.duplicate-set-ticket');
          this.titleDuplicateSetTicket = this.titleFormData;
          break;
        case this.titleEditSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.edit-set-ticket');
          this.titleEditSetTicket = this.titleFormData;
          break;
        case this.titleAddReservation:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
          this.titleAddReservation = this.titleFormData;
          break;
        case this.titleEditReservation:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
          this.titleEditReservation = this.titleFormData;
          break;
      }
    }
  }

  /**
   * checkDisplayInput
   * @returns
   */
  checkDisplayInput(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (
      this.titleFormData == this.titleEditSetTicket ||
      this.titleFormData == this.titleEditReservation ||
      this.titleFormData == this.titleAddReservation
    ) {
      return false;
    }
    if (this.titleFormData == this.titleNewRegistrationSetTicket || this.titleFormData == this.titleDuplicateSetTicket) {
      return true;
    }
  }

  /**
   * checkDisplayInputSaleTicketTypeId
   * @returns
   */
  checkDisplaySaleTicketTypeId(): boolean {
    if (
      (this.titleFormData &&
        (this.titleFormData == this.titleNewRegistrationSetTicket || this.titleFormData == this.titleDuplicateSetTicket)) ||
      !this.setTicketSelected
    ) {
      return false;
    }
    return true;
  }

  /**
   * checkInputSaleStartAt
   * @returns
   */
  checkInputSaleStartAt(): boolean {
    if (
      !this.titleFormData ||
      (this.titleFormData && this.titleFormData == this.titleEditSetTicket && this.setTicketSelected.status !== 0) ||
      (this.titleFormData && this.titleFormData == this.titleAddReservation) ||
      (this.titleFormData && this.titleFormData == this.titleEditReservation)
    ) {
      return false;
    }
    return true;
  }

  /**
   * checkInputSaleEndAt
   * @returns
   */
  checkInputSaleEndAt(): boolean {
    if (
      !this.titleFormData ||
      (this.titleFormData && this.titleFormData == this.titleAddReservation) ||
      (this.titleFormData && this.titleFormData == this.titleEditReservation)
    ) {
      return false;
    }
    return true;
  }

  /**
   * getTicketName
   * @param ticketName
   * @returns
   */
  getTicketName(ticket: any): string {
    if (this.languageKey == 'en') {
      return ticket.ticketName.en ? ticket.ticketName.en : ticket.ticketName.ja;
    } else {
      return ticket.ticketName.ja ? ticket.ticketName.ja : ticket.ticketName.en;
    }
  }

  /**
   * checkDisplayApplicationStartDate
   * @returns
   */
  checkDisplayApplicationStartDate(): boolean {
    if (!this.titleFormData) {
      return this.setTicketSelected ? this.isChooseDetailUpdate : false;
    } else {
      return [this.titleAddReservation, this.titleEditReservation].includes(this.titleFormData);
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * clearDatePicker
   * @param itemName
   */
  public clearDatePicker(itemName: string): void {
    this.formSaleTicket.value[itemName] = null;
    this.ticketSetDetail[`isClear${itemName}`] = true;
    this.ticketSetDetail[`isClear${itemName}Time`] = true;
    this.changeDetectorRef.detectChanges();
    if (['availableDeadlineAt', 'refundDeadlineAt'].includes(itemName)) {
      this.formSaleTicket.get('saleEndAt').clearValidators();
      this.formSaleTicket.get('saleEndAt').updateValueAndValidity();
    }
  }

  /**
   * getStatus
   * @param status
   */
  public getStatus(status: number): String {
    const statusObj = [
      {
        id: 0,
        nameJp: '販売開始前',
        nameEn: 'Before sales start'
      },
      {
        id: 1,
        nameJp: '販売中',
        nameEn: 'On sale'
      },
      {
        id: 2,
        nameJp: '販売休止',
        nameEn: 'Sales suspended'
      },
      {
        id: 3,
        nameJp: '販売終了',
        nameEn: 'Sale ends'
      }
    ];
    let statusName;
    statusObj.forEach(e => {
      if (e.id == status) {
        statusName = this.languageKey == 'en' ? e.nameEn : e.nameJp;
      }
    });
    return statusName;
  }

  /**
   * checkChangeCategory
   * @param input
   */
  public checkChangeCategory(input: string, isEdit?: boolean): void {
    if (input == '0') {
      if (this.formSaleTicket.value?.adultSelection) {
        if (this.formSaleTicket.value?.priceType == 'no_cost') {
          const properties = ['priceAdult', 'refundFeeMethod', 'refundPeriod', 'refundPeriodUnit'];
          properties.forEach(element => {
            this.clearValidatorsAndDoNotChangeTheValue(element);
          });
          if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
            this.clearValidatorsAndDoNotChangeTheValue('refundFeeAdult');
          } else if (this.formSaleTicket.value?.refundFeeMethod == this.PER_TICKET) {
            this.clearValidatorsAndDoNotChangeTheValue('refundFeeTicket');
          }
        } else if (this.formSaleTicket.value?.priceType == 'addition') {
          this.clearValidatorsAndDoNotChangeTheValue('priceAdult');
        } else {
          this.setValidatorsAndDoNotChangeTheValue('priceAdult', Validators.required);
        }
        this.setValidatorsAndDoNotChangeTheValue('minCountAdult', Validators.required);
        this.setValidatorsAndDoNotChangeTheValue('maxCountAdult', Validators.required);

        if (this.formSaleTicket.value?.priceType !== 'no_cost') {
          if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
            this.setValidatorsAndDoNotChangeTheValue('refundFeeAdult', Validators.required);
          } else if (this.formSaleTicket.value?.refundFeeMethod == this.PER_TICKET) {
            this.setValidatorsAndDoNotChangeTheValue('refundFeeTicket', Validators.required);
          }
          if (!this.hasReserveTickets()) {
            if (!Helper.isEmpty(this.formSaleTicket.value?.refundPeriod)) {
              this.validateOptinalFieds('refundPeriod', null, null, 1, 365);
            }
            if (this.formSaleTicket.value?.refundPeriodUnit?.length) {
              this.validateOptinalFieds('refundPeriodUnit', null, null, null, null);
            }
          }
        }
      } else {
        this.clearValidatorsAndDoNotChangeTheValue('priceAdult');
        this.clearValidatorsAndDoNotChangeTheValue('minCountAdult');
        this.clearValidatorsAndDoNotChangeTheValue('maxCountAdult');
        if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
          this.clearValidatorsAndDoNotChangeTheValue('refundFeeAdult');
        }
      }
    } else {
      if (this.formSaleTicket.value?.[`custom${input}Selection`]) {
        if (this.formSaleTicket.value?.priceType == 'no_cost') {
          const customProperties = [
            `priceCustom${input}`,
            'refundFeeMethod',
            `refundFeeCustom${input}`,
            'refundPeriod',
            'refundPeriodUnit'
          ];
          customProperties.forEach(element => {
            this.clearValidatorsAndDoNotChangeTheValue(element);
          });
          if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
            this.clearValidatorsAndDoNotChangeTheValue(`refundFeeCustom${input}`);
          } else if (this.formSaleTicket.value?.refundFeeMethod == this.PER_TICKET) {
            this.clearValidatorsAndDoNotChangeTheValue('refundFeeTicket');
          }
        } else if (this.formSaleTicket.value?.priceType == 'addition') {
          this.clearValidatorsAndDoNotChangeTheValue(`priceCustom${input}`);
        } else {
          this.setValidatorsAndDoNotChangeTheValue(`priceCustom${input}`, Validators.required);
        }

        this.languages.forEach(element => {
          this.setValidatorsAndDoNotChangeTheValue(`custom${input}Name${element}`, this.noWhitespaceValidator);
          if (isEdit) {
            this.formSaleTicket.get(`custom${input}Name${element}`).markAsUntouched();
            this.formSaleTicket.get(`custom${input}Name${element}`).markAsPristine();
          }
        });

        this.setValidatorsAndDoNotChangeTheValue(`minCountCustom${input}`, Validators.required);
        this.setValidatorsAndDoNotChangeTheValue(`maxCountCustom${input}`, Validators.required);

        if (this.formSaleTicket.value?.priceType !== 'no_cost') {
          if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
            this.setValidatorsAndDoNotChangeTheValue(`refundFeeCustom${input}`, Validators.required);
          } else if (this.formSaleTicket.value?.refundFeeMethod == this.PER_TICKET) {
            this.setValidatorsAndDoNotChangeTheValue('refundFeeTicket', Validators.required);
          }
          if (!this.hasReserveTickets()) {
            if (!Helper.isEmpty(this.formSaleTicket.value?.refundPeriod)) {
              this.validateOptinalFieds('refundPeriod', null, null, 1, 365);
            }
            if (this.formSaleTicket.value?.refundPeriodUnit?.length) {
              this.validateOptinalFieds('refundPeriodUnit', null, null, null, null);
            }
          }
        }
      } else {
        this.clearValidatorsAndDoNotChangeTheValue(`priceCustom${input}`);
        this.languages.forEach(element => {
          this.clearValidatorsAndDoNotChangeTheValue(`custom${input}Name${element}`);
        });
        this.clearValidatorsAndDoNotChangeTheValue(`minCountCustom${input}`);
        this.clearValidatorsAndDoNotChangeTheValue(`maxCountCustom${input}`);
        if (this.formSaleTicket.value?.refundFeeMethod == this.PRICE_CLASS) {
          this.clearValidatorsAndDoNotChangeTheValue(`refundFeeCustom${input}`);
        }
      }
    }
  }

  /**
   * setValidatorsAndDoNotChangeTheValue
   * @param formControlName
   * @param validator
   */
  setValidatorsAndDoNotChangeTheValue(formControlName: string, validator: ValidatorFn | ValidatorFn[]) {
    const control = this.formSaleTicket.get(formControlName);
    const validatorsArray = Array.isArray(validator) ? validator : [validator];
    const isFormTouchedOrDirty = control.touched || control.dirty;
    control.setValidators(validatorsArray);
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.changeDetectorRef.detectChanges();
    if (isFormTouchedOrDirty) {
      this.bubblesInput([formControlName]);
    }
  }

  /**
   * clearValidatorsAndDoNotChangeTheValue
   * @param formControlName
   * @param validator
   */
  clearValidatorsAndDoNotChangeTheValue(formControlName: string) {
    const control = this.formSaleTicket.get(formControlName);
    const isFormTouchedOrDirty = control.touched || control.dirty;
    control.clearValidators();
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.changeDetectorRef.detectChanges();
    if (isFormTouchedOrDirty) {
      this.bubblesInput([formControlName]);
    }
  }

  /**
   * checkChangeRefundFeeMethod
   * @param refundFeeMethod
   */
  public checkChangeRefundFeeMethod(refundFeeMethod: number): void {
    const PRICE_CLASS_VALUE = 1;
    if (refundFeeMethod && refundFeeMethod == PRICE_CLASS_VALUE) {
      this.formSaleTicket.get('refundFeeTicket').clearValidators();
      this.formSaleTicket.get('refundFeeTicket').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      if (this.formSaleTicket.value?.adultSelection) {
        this.formSaleTicket.get('refundFeeAdult').setValidators(Validators.required);
        this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      if (this.formSaleTicket.value?.custom1Selection) {
        this.formSaleTicket.get('refundFeeCustom1').setValidators(Validators.required);
        this.formSaleTicket.get('refundFeeCustom1').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      if (this.formSaleTicket.value?.custom2Selection) {
        this.formSaleTicket.get('refundFeeCustom2').setValidators(Validators.required);
        this.formSaleTicket.get('refundFeeCustom2').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      if (this.formSaleTicket.value?.custom3Selection) {
        this.formSaleTicket.get('refundFeeCustom3').setValidators(Validators.required);
        this.formSaleTicket.get('refundFeeCustom3').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    } else {
      this.formSaleTicket.get('refundFeeTicket').setValidators(Validators.required);
      this.formSaleTicket.get('refundFeeTicket').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('refundFeeAdult').clearValidators();
      this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('refundFeeCustom1').clearValidators();
      this.formSaleTicket.get('refundFeeCustom1').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('refundFeeCustom2').clearValidators();
      this.formSaleTicket.get('refundFeeCustom2').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.formSaleTicket.get('refundFeeCustom3').clearValidators();
      this.formSaleTicket.get('refundFeeCustom3').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * displayDate
   * @param time
   * @returns
   */
  displayDate(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 16).replace('T', ' ');
  }

  /**
   * getTicketType
   * @param ticketType
   * @returns
   */
  getTicketType(ticketType: string): string {
    if (!ticketType) {
      return;
    }
    if (ticketType == 'coupon') {
      return '綴り券';
    } else if (ticketType == 'ticket') {
      return '企画券';
    } else if (ticketType == 'combo') {
      return 'セット券';
    }
  }

  /**
   * defineLanguages
   * @param listApp
   * @param appIdSelected
   * @param isEdit
   * @returns
   */
  defineLanguages(appIdSelected?: string) {
    if (!appIdSelected) {
      if (this.languageKey == 'jp') {
        this.languages = ['ja'];
      } else {
        this.languages = [this.languageKey];
      }
    } else {
      let indexApp = this.listApp?.findIndex(e => e.appId == appIdSelected);
      this.languages = [];
      if (indexApp !== -1) {
        this.languages = Constant.LANGUAGES_SETTING.filter(e =>
          this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
        )?.map(e => e.translation_language_code);
        this.languages = Helper.getLanguagesCode(this.languages, this.commonService);
        this.languages = this.reorderLanguages(this.languages, this.languageSelected);
      }
    }
  }

  /**
   * getTheKeyOfTheObject
   * @param languages
   * @param languageSelected
   * @param isEditSaleTicketDetail
   * @param obj
   * @returns
   */
  getTheKeyOfTheObject(languages: string[], languageSelected: string, isEditSaleTicketDetail: boolean, obj?: any): any[] {
    if (isEditSaleTicketDetail) {
      if (!languages || (languages && languages.length == 0)) {
        return [languageSelected];
      } else {
        return languages ? languages : [];
      }
    } else {
      return obj ? Object.keys(obj) : [];
    }
  }

  /**
   * getLanguageFromLanguagesSetting
   * @param keyLanguage
   * @returns
   */
  getLanguageFromLanguagesSetting(keyLanguage: string): any {
    if (!keyLanguage) {
      return '';
    }
    return Constant.LANGUAGES_SETTING.find(e => e.translation_language_code == keyLanguage)
      ? Constant.LANGUAGES_SETTING.find(e => e.translation_language_code == keyLanguage)
      : '';
  }

  /**
   * deleteMultilingualFields
   */
  deleteMultilingualFields() {
    Object.keys(this.formSaleTicket.controls).forEach(controlName => {
      if (
        controlName.startsWith('saleTicketName') ||
        controlName.startsWith('companyName') ||
        controlName.startsWith('subtitle') ||
        controlName.startsWith('note') ||
        controlName.startsWith('custom1Name') ||
        controlName.startsWith('custom2Name') ||
        controlName.startsWith('custom3Name') ||
        controlName.startsWith('custom1Note') ||
        controlName.startsWith('custom2Note') ||
        controlName.startsWith('custom3Note')
      ) {
        this.formSaleTicket.removeControl(controlName);
      }
    });
  }

  /**
   * updateMultilingualFields
   * @param languages
   */
  updateMultilingualFields(languages: string[]) {
    languages.forEach(element => {
      this.formSaleTicket.addControl(
        `saleTicketName${element}`,
        this.formBuilder.control('', [this.noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(100)])
      );
      this.formSaleTicket.addControl(`companyName${element}`, this.formBuilder.control(''));
      this.formSaleTicket.addControl(
        `subtitle${element}`,
        this.formBuilder.control('', [Validators.minLength(1), Validators.maxLength(100)])
      );
      let numberOfCharactersInTheNoteCategory = element == 'ja' ? 1000 : 2000;
      this.formSaleTicket.addControl(
        `note${element}`,
        this.formBuilder.control('', [Validators.maxLength(numberOfCharactersInTheNoteCategory)])
      );
      this.formSaleTicket.addControl(`custom1Name${element}`, this.formBuilder.control('', this.formBuilder.control('')));
      this.formSaleTicket.addControl(`custom2Name${element}`, this.formBuilder.control('', this.formBuilder.control('')));
      this.formSaleTicket.addControl(`custom3Name${element}`, this.formBuilder.control('', this.formBuilder.control('')));
      this.formSaleTicket.addControl(`custom1Note${element}`, this.formBuilder.control('', this.formBuilder.control('')));
      this.formSaleTicket.addControl(`custom2Note${element}`, this.formBuilder.control('', this.formBuilder.control('')));
      this.formSaleTicket.addControl(`custom3Note${element}`, this.formBuilder.control('', this.formBuilder.control('')));
    });
    this.checkChangeCategory('0');
    this.checkChangeCategory('1');
    this.checkChangeCategory('2');
    this.checkChangeCategory('3');
  }

  /**
   * onAppIdChange
   * @param event
   * @returns
   */
  onAppIdChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (!selectedValue) {
      return;
    }
    this.initFormSaleTicket();
    this.initFormCancellationFee();
    this.clearDatePicker('saleStartAt');
    this.clearDatePicker('saleEndAt');
    this.formSaleTicket.patchValue({
      appId: selectedValue
    });
    this.appName = this.listApp.find(e => e.appId == selectedValue).appName;
    this.getListCombinationTicket(selectedValue);
    this.defineLanguages(selectedValue);
    this.updateMultilingualFields(this.languages);
  }

  /**
   * getListCombinationTicket
   * @param appId
   */
  async getListCombinationTicket(appId: string): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.getTickets(this.informationAccount, appId).subscribe(res => {
        if (!res || !res.length) {
          this.listCombinationTicket = [];
          this.listCombinationTicketOrigin = [];
          return;
        }
        this.listCombinationTicket = res;
        this.listCombinationTicketOrigin = _.cloneDeep(this.listCombinationTicket);
        if (this.listCombinationTicket.length) {
          this.changeDetectorRef.detectChanges();
          if (this.listTicket) {
            this.listTicket.nativeElement.scrollTop = 0;
          }
        }
        resolve();
      });
    });
  }

  /**
   * convertForValue
   * @param property
   * @returns
   */
  convertForValue(property: string): string {
    if (!this.ticketSetDetail || !this.ticketSetDetail[property]) {
      return '';
    }
    if (!this.languages || this.languages.length === 0) {
      return '';
    }

    return this.ticketSetDetail[property][this.languages[0]];
  }

  /**
   * downloadQrSVG
   */
  downloadQrSVG(): void {
    if (!this.setTicketSelected) {
      return;
    }
    const fileNameResponse = `QR_${
      this.languageKey == 'jp' ? this.setTicketSelected.ticketName.ja : this.setTicketSelected?.ticketName.en
    }.svg`;
    const svgContent = this.base64Selected;
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const file = new File([blob], fileNameResponse, {
      type: 'image/svg+xml'
    });
    fileSaver.saveAs(file);
  }

  /***
   * downloadQrPNG
   */
  downloadQrPNG(): void {
    if (!this.setTicketSelected) {
      return;
    }
    const fileNameResponse = `QR_${
      this.languageKey == 'jp' ? this.setTicketSelected.ticketName.ja : this.setTicketSelected?.ticketName.en
    }.png`;
    this.ticketEditorService.generateQRCodeInPngFormat(this.setTicketSelected.saleTicketDetail.saleTicketUrl).subscribe(
      res => {
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNameResponse;
        link.click();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.common-error')
          }
        });
      }
    );
  }

  getPriceType(input: string): string {
    if (!input) {
      return '';
    }
    if (input == 'fixed') {
      return this.translateService.instant('ticket-editor.combination.fixed-fee');
    } else if (input == 'addition') {
      return this.translateService.instant('ticket-editor.combination.addition-method');
    } else if (input == 'no_cost') {
      return this.translateService.instant('ticket-editor.combination.free-tickets');
    }
  }

  /**
   *sortAndFilter
   */
  sortAndFilter() {
    this.isSortFilter = !this.isSortFilter;
    this.mapFilterOption = new Map();

    if (!this.isSortFilter) {
      this.resetSortFilter();
      if (this.listSaleTicket && this.listSaleTicket.length) {
        this.headerColumns.forEach(e => {
          this.clearFilter(e.property);
          e.isSortBy = Constant.EMPTY;
        });
        this.getDetailTicketSet(this.listSaleTicketDisplay[Constant.FIRST_ELEMENT_INDEX]['saleTicketId'], null);
        if (this.listDisplay) {
          this.listDisplay.nativeElement.scrollTop = 0;
        }
      }
    }
  }

  /**
   * resetSortFilter
   */
  resetSortFilter() {
    this.listSaleTicketDisplay = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket));
    this.resetColumnsSort();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
  }
  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    //namhocgioi code
    // let listFilterTmp = this.convertListOrder(_.cloneDeep(this.listSaleTicket));
    let listFilterTmp = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket));

    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);
    listDataTableGetOptionFilter?.sort((a, b) => {
      // Kiểm tra xem a có thuộc tính lastFilter không
      const hasLastFilterA = a.hasOwnProperty('lastFilter') && a['lastFilter'] != undefined;
      // Kiểm tra xem b có thuộc tính lastFilter không
      const hasLastFilterB = b.hasOwnProperty('lastFilter') && b['lastFilter'] != undefined;

      // So sánh và đưa các phần tử không có lastFilter lên đầu
      if (!hasLastFilterA && hasLastFilterB) {
        return -1;
      } else if (hasLastFilterA && !hasLastFilterB) {
        return 1;
      } else {
        return 0;
      }
    });

    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);

    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.mapFilterOption.set(property, this.listFilterDisplay);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin?.every(filter => filter.isChecked);
  }

  /***
   * getUniqueOption
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.listSaleTicketDisplay?.sort(this.dynamicSortMultiple(this.listSorted));
    if (this.listSaleTicketDisplay && this.listSaleTicketDisplay.length) {
      this.getDetailTicketSet(this.listSaleTicketDisplay[Constant.FIRST_ELEMENT_INDEX]['saleTicketId'], null);
    }
    if (this.listDisplay) {
      this.listDisplay.nativeElement.scrollTop = 0;
    }
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      e.headerName = this.translateService.instant(e.headerName);
    });
    // propertySorts[propertySorts.length - 2].headerName = this.translateService.instant('timetable-editor.display-1');
    // if (this.isDisplay2) {
    //   propertySorts[propertySorts.length - 1].headerName = this.translateService.instant('timetable-editor.display-2');
    // } else {
    //   propertySorts.pop();
    // }
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        // sort
        // this.listSaleTicketDisplay = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket?.filter(data => !data[this.LAST_FILTER])));
        this.listSaleTicketDisplay?.sort(this.dynamicSortMultiple(this.listSorted));
        if (this.listSaleTicketDisplay && this.listSaleTicketDisplay.length) {
          this.getDetailTicketSet(this.listSaleTicketDisplay[Constant.FIRST_ELEMENT_INDEX]['saleTicketId'], null);
        }
        if (this.listDisplay) {
          this.listDisplay.nativeElement.scrollTop = 0;
        }
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    if (this.mapFilterOption.has(property)) {
      this.mapFilterOption.delete(property);
    }
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    // this.headerColumns.find(data => data.property == property).isSortBy = Constant.EMPTY;
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      // if (this.isSearching) {
      this.filterOder(property, false);
      // }
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.listSaleTicket?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTimetableGetOptionFilter = this.listSaleTicket?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      listTimetableGetOptionFilter = this.convertListSaleTicket(_.cloneDeep(listTimetableGetOptionFilter));
      listTimetableGetOptionFilter?.sort((a, b) => {
        // Kiểm tra xem a có thuộc tính lastFilter không
        const hasLastFilterA = a.hasOwnProperty('lastFilter') && a['lastFilter'] != undefined;
        // Kiểm tra xem b có thuộc tính lastFilter không
        const hasLastFilterB = b.hasOwnProperty('lastFilter') && b['lastFilter'] != undefined;

        // So sánh và đưa các phần tử không có lastFilter lên đầu
        if (!hasLastFilterA && hasLastFilterB) {
          return -1;
        } else if (hasLastFilterA && !hasLastFilterB) {
          return 1;
        } else {
          return 0;
        }
      });
      let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTimetableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTimetableOptionFilter[i].lastFilter,
          name: listTimetableOptionFilter[i][nextProperTyFilter]
        };
      }
      if (listOptionFilterNew.length > 0) {
        for (let element of listOptionFilterNew) {
          for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
            if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
              element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
            }
          }
        }
      }
      // set new list option filter for next property filter in listCurrentFilter
      if (nextProperTyFilter == property) {
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      }
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.controlCheckBoxCheckAllFilter();
      this.filterAfterClear();
    }
  }

  /**
   * filterAfterClear
   */
  filterAfterClear() {
    let listFilterTmp = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket));
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == 'undefined') continue;
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    //
    this.listSaleTicketDisplay = listFilterTmp;
    this.listSaleTicketDisplay?.sort(this.dynamicSortMultiple(this.listSorted));
    if (this.listSaleTicketDisplay?.length) {
      this.getDetailTicketSet(this.listSaleTicketDisplay[Constant.FIRST_ELEMENT_INDEX].saleTicketId, null);
    } else {
      this.setTicketSelected = undefined;
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * change checked
   * @param index index of option filter
   */
  checkOptionFilter(index: number) {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string, isFilterFirstTime: boolean) {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    if (this.mapFilterOption.has(property) && _.isEqual(this.mapFilterOption.get(property), this.listFilterDisplayOrigin)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.listSaleTicket?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTimetableGetOptionFilter = this.listSaleTicket?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        listTimetableGetOptionFilter = this.convertListSaleTicket(_.cloneDeep(listTimetableGetOptionFilter));
        listTimetableGetOptionFilter.sort((a, b) => {
          // Kiểm tra xem a có thuộc tính lastFilter không
          const hasLastFilterA = a.hasOwnProperty('lastFilter') && a['lastFilter'] != undefined;
          // Kiểm tra xem b có thuộc tính lastFilter không
          const hasLastFilterB = b.hasOwnProperty('lastFilter') && b['lastFilter'] != undefined;

          // So sánh và đưa các phần tử không có lastFilter lên đầu
          if (!hasLastFilterA && hasLastFilterB) {
            return -1;
          } else if (hasLastFilterA && !hasLastFilterB) {
            return 1;
          } else {
            return 0;
          }
        });
        let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTimetableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTimetableOptionFilter[i].lastFilter,
            name: listTimetableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Timetable show up on screen
    // this.dataFilterOrder?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    //namhocgioi code
    let listFilterTmp = this.convertListSaleTicket(_.cloneDeep(this.listSaleTicket));
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == 'undefined') continue;
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    //
    this.listSaleTicketDisplay = listFilterTmp;
    this.listSaleTicketDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;

    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      if (this.listSaleTicketDisplay?.length) {
        this.getDetailTicketSet(this.listSaleTicketDisplay[Constant.FIRST_ELEMENT_INDEX].saleTicketId, null);
      } else {
        this.setTicketSelected = undefined;
      }
    }
    if (this.listDisplay) {
      this.listDisplay.nativeElement.scrollTop = 0;
    }
  }

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.listSaleTicket?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.listSaleTicket?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * checkObjectType
   * @param input
   * @returns
   */
  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * convertListSaleTicket
   * @param listSaleTicket
   * @returns
   */
  convertListSaleTicket(listSaleTicket: any) {
    if (!listSaleTicket || !listSaleTicket.length) {
      return;
    }
    listSaleTicket.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
      if (e.priceAdult) {
        e['salesPrice'] = e.priceAdult;
      } else {
        e['salesPrice'] = ' - ';
      }
      if (e.priceCustom1) {
        e['salesPrice'] = `${e['salesPrice']} / ${e.priceCustom1}`;
      } else {
        e['salesPrice'] = `${e['salesPrice']} / - `;
      }
      if (e.priceCustom2) {
        e['salesPrice'] = `${e['salesPrice']} / ${e.priceCustom2}`;
      } else {
        e['salesPrice'] = `${e['salesPrice']} / - `;
      }
      if (e.priceCustom3) {
        e['salesPrice'] = `${e['salesPrice']} / ${e.priceCustom3}`;
      } else {
        e['salesPrice'] = `${e['salesPrice']} / - `;
      }
      e['salesStatus'] = this.getStatus(e.status);
      e['updateScheduleAt'] = this.displayDate(e.updateScheduleAt);
      if (this.languageKey != 'en') {
        e['ticketType'] = this.getTicketType(e.ticketType);
      }
      e['isReserve'] = e['isReserve'] ? this.translateService.instant('ticket-editor.combination.yes') : '';
    });

    return listSaleTicket;
  }

  /**
   * openTicketUpdate
   */
  openTicketUpdate() {
    this.ticketSetDetail?.updateScheduleAt ? this.editSaleTicketUpdate() : this.addSaleTicketUpdate();
  }

  /**
   * getIsSale
   * @param input
   * @returns
   */
  getIsSale(input: any): string {
    if (input == null || input == undefined) {
      return;
    }
    if (input == true) {
      return this.translateService.instant('ticket-editor.combination.sale');
    } else {
      return this.translateService.instant('ticket-editor.combination.pause');
    }
  }

  /**
   * reorderLanguages
   * @param languages
   * @param languageSelected
   * @returns
   */
  reorderLanguages(languages: any[], languageSelected: string): any[] {
    const reorderedLanguages = [...languages];

    const selectedLanguageIndex = reorderedLanguages.findIndex(lang => lang == languageSelected);

    if (selectedLanguageIndex !== -1) {
      const selectedLanguage = reorderedLanguages[selectedLanguageIndex];
      reorderedLanguages.splice(selectedLanguageIndex, 1);
      reorderedLanguages.unshift(selectedLanguage);
    } else {
      const defaultLanguageCode = languageSelected == 'en' ? 'ja' : 'en';
      const defaultLanguageIndex = reorderedLanguages.findIndex(lang => lang == defaultLanguageCode);

      if (defaultLanguageIndex !== -1) {
        const defaultLanguage = reorderedLanguages[defaultLanguageIndex];
        reorderedLanguages.splice(defaultLanguageIndex, 1);
        reorderedLanguages.unshift(defaultLanguage);
      }
    }

    return reorderedLanguages;
  }

  /**
   * hasReserveTickets
   * @returns
   */
  hasReserveTickets(): boolean {
    return this.ticketSetDetail?.combinationTicket?.some(e => e.isReserve) ?? false;
  }

  /**
   * hasReserveTickets
   * @returns
   */
  hasNoReserveTickets(): boolean {
    return this.ticketSetDetail?.combinationTicket?.some(e => !e.isReserve) ?? false;
  }

  /**
   * checkChangeCancellationPolicySettingStatus
   * @param status
   */
  checkChangeCancellationPolicySettingStatus(status: string): void {
    if (status == 'T') {
      for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
        if (index != 0) {
          this.formCancellationFee.get(`period${index}`).setValidators(this.noWhitespaceValidator);
          this.formCancellationFee.get(`period${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
          this.formCancellationFee.get(`periodUnit${index}`).setValidators(Validators.required);
          this.formCancellationFee.get(`periodUnit${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
        this.formCancellationFee.get(`cancellationFeePercentage${index}`).setValidators(Validators.required);
        this.formCancellationFee.get(`cancellationFeePercentage${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      this.changeDetectorRef.detectChanges();
      if (this.detailContainer) {
        this.detailContainer.nativeElement.scrollTop = this.detailContainer.nativeElement.scrollHeight;
      }
    } else if (status == 'F') {
      for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
        if (index != 0) {
          this.formCancellationFee.get(`period${index}`).clearValidators();
          this.formCancellationFee.get(`period${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
          this.formCancellationFee.get(`periodUnit${index}`).clearValidators();
          this.formCancellationFee.get(`periodUnit${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
        this.formCancellationFee.get(`cancellationFeePercentage${index}`).clearValidators();
        this.formCancellationFee.get(`cancellationFeePercentage${index}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }
  }

  /**
   * getLabelCancellationCharge
   * @param index
   * @returns
   */
  getLabelCancellationCharge(index: number): string {
    return Helper.formatString(this.translateService.instant(`ticket-editor.combination.cancellation-charge`), `${index}`);
  }

  /**
   * addRowCancellationFee
   */
  addRowCancellationFee(): void {
    this.ticketSetDetail.cancelPolicies.push(new CancelPolicies(1, 'days', 0));
    this.formCancellationFee.addControl(
      `period${this.ticketSetDetail.cancelPolicies.length - 1}`,
      this.formBuilder.control(1, [this.noWhitespaceValidator])
    );
    this.formCancellationFee.addControl(
      `periodUnit${this.ticketSetDetail.cancelPolicies.length - 1}`,
      this.formBuilder.control('days', [Validators.required])
    );
    this.formCancellationFee.addControl(
      `cancellationFeePercentage${this.ticketSetDetail.cancelPolicies.length - 1}`,
      this.formBuilder.control(0, [Validators.required])
    );
    this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
    this.changeDetectorRef.detectChanges();
    if (this.detailContainer) {
      this.detailContainer.nativeElement.scrollTop = this.detailContainer.nativeElement.scrollHeight;
    }
  }

  /**
   * deleteRowCancellationFee
   * @param indexRow
   */
  deleteRowCancellationFee(indexRow: number) {
    const formValue = this.formCancellationFee.value;
    let oldValue = [];
    for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
      let formElement = {
        period: formValue[`period${index}`],
        periodUnit: formValue[`periodUnit${index}`],
        cancellationFeePercentage: formValue[`cancellationFeePercentage${index}`]
      };
      oldValue.push(formElement);
    }
    this.ticketSetDetail.cancelPolicies.splice(indexRow, 1);
    this.changeDetectorRef.detectChanges();
    oldValue.splice(indexRow, 1);
    this.deleteAllValidateCancelFee();
    for (let index = 0; index < this.ticketSetDetail.cancelPolicies.length; index++) {
      const policy = oldValue[index];
      if (index == 0) {
        this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period));
        this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit));
      } else {
        this.formCancellationFee.addControl(`period${index}`, this.formBuilder.control(policy.period, [this.noWhitespaceValidator]));
        this.formCancellationFee.addControl(`periodUnit${index}`, this.formBuilder.control(policy.periodUnit, [Validators.required]));
      }
      this.formCancellationFee.addControl(
        `cancellationFeePercentage${index}`,
        this.formBuilder.control(policy.cancellationFeePercentage, [Validators.required])
      );
    }
    this.markTheElement(this.formCancellationFee, 'CancellationFeeForm');
  }

  /**
   *deleteAllValidateCancelFee
   */
  deleteAllValidateCancelFee(): void {
    Object.keys(this.formCancellationFee.controls).forEach(controlName => {
      if (controlName.startsWith('period') || controlName.startsWith('periodUnit') || controlName.startsWith('cancellationFeePercentage')) {
        this.formCancellationFee.removeControl(controlName);
      }
    });
    this.formCancellationFee.reset();
  }

  /**
   * initFormCancellationFee
   */
  initFormCancellationFee(): void {
    this.formCancellationFee = this.formBuilder.group(
      {
        period0: [0],
        periodUnit0: ['days'],
        cancellationFeePercentage0: [0, Validators.required],
        period1: [1, this.noWhitespaceValidator],
        periodUnit1: ['days', Validators.required],
        cancellationFeePercentage1: [0, Validators.required]
      },
      { validators: this.uniquePeriodValidator }
    );
  }

  /**
   * restrictToNumbers
   * @param event
   * @param formGroup
   * @param formControlName
   * @param minValue
   * @param maxValue
   */
  restrictToNumbers(event: Event, formGroup: string, formControlName: string, minValue?: number, maxValue?: number): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const previousValue = input.dataset.previousValue || '';
    const sanitizedValue = currentValue.replace(/[^\u0030-\u0039]/g, '');

    const parsedValue = parseInt(sanitizedValue, 10);

    // Check values ​​with minValue and maxValue only if they are passed in
    const isOutOfRange = (!Helper.isEmpty(minValue) && parsedValue < minValue) || (!Helper.isEmpty(maxValue) && parsedValue > maxValue);

    if (isOutOfRange) {
      input.value = previousValue;
    } else {
      input.dataset.previousValue = sanitizedValue;
    }

    const patchObj = {};
    patchObj[formControlName] = isOutOfRange ? previousValue : sanitizedValue;

    formGroup === this.IS_FORM_SALE_TICKET ? this.formSaleTicket.patchValue(patchObj) : this.formCancellationFee.patchValue(patchObj);

    this.changeDetectorRef.detectChanges();
  }

  /**
   * validatePriceCategories
   */
  validatePriceCategories(): void {
    this.checkChangeCategory('0');
    this.checkChangeCategory('1');
    this.checkChangeCategory('2');
    this.checkChangeCategory('3');
  }

  /**
   * uniquePeriodValidator
   * @param formGroup
   * @returns
   */
  uniquePeriodValidator(formGroup: AbstractControl): { [key: string]: any } | null {
    if (!(formGroup instanceof FormGroup)) {
      throw new Error('uniquePeriodValidator can only be used on FormGroup instances');
    }

    const periodKeys = Object.keys(formGroup.controls).filter(key => /^period\d+$/.test(key));
    const periodControls = periodKeys.map(key => ({ key, control: formGroup.get(key) }));

    const validPeriods = periodControls
      .filter(period => {
        const value = period.control.value;
        return (typeof value === 'string' && value.trim().length > 0) || typeof value === 'number';
      })
      .map(period => ({ key: period.key, value: parseFloat(period.control.value) }));

    // Clear previous errors
    periodControls.forEach(period => {
      const control = period.control;
      if (control.errors) {
        const { notInOrder, ...errors } = control.errors;
        control.setErrors(Object.keys(errors).length ? errors : null);
      }
    });

    // Flag to track if any errors are found
    let hasOrderError = false;

    for (let i = 0; i < validPeriods.length; i++) {
      const current = validPeriods[i];
      const prev = validPeriods[i - 1];
      const next = validPeriods[i + 1];

      // Check with previous value
      if (prev && current.value <= prev.value) {
        hasOrderError = true;
        const controlPrev = formGroup.get(prev.key);
        const controlCurrent = formGroup.get(current.key);
        if (controlPrev) {
          controlPrev.setErrors({ ...controlPrev.errors, notInOrder: true });
        }
        if (controlCurrent) {
          controlCurrent.setErrors({ ...controlCurrent.errors, notInOrder: true });
        }
      }

      // Check with the following value
      if (next && current.value >= next.value) {
        hasOrderError = true;
        const controlNext = formGroup.get(next.key);
        const controlCurrent = formGroup.get(current.key);
        if (controlNext) {
          controlNext.setErrors({ ...controlNext.errors, notInOrder: true });
        }
        if (controlCurrent) {
          controlCurrent.setErrors({ ...controlCurrent.errors, notInOrder: true });
        }
      }
    }

    // Return group-level errors
    if (hasOrderError) {
      return { notInOrder: true };
    } else {
      return null;
    }
  }

  /**
   * getValueCancelPolicies
   * @param period
   * @param periodUnit
   * @param cancellationFeePercentage
   * @returns
   */
  getValueCancelPolicies(period: number, periodUnit: string, cancellationFeePercentage: number): string {
    return `${!Helper.isEmpty(period) ? period : ''}${this.languageKey == 'en' ? ' ' : ''}${
      periodUnit == 'days' ? this.translateService.instant('ticket-editor.combination.day') : ''
    }${this.translateService.instant('ticket-editor.combination.until-the-day-before')}${' '}${
      !Helper.isEmpty(cancellationFeePercentage) ? cancellationFeePercentage : ''
    }${'%'}`;
  }

  /**
   * getPriceCategoryLabel
   */
  getPriceCategoryLabel(ticket: Ticket): string {
    if (!ticket) {
      return '';
    }
    let label = `${ticket.isInUseAdult ? '大人' : '-'} / ${ticket.isInUseCustom1 ? ticket.priceLabelCustom1 : '-'} / ${
      ticket.isInUseCustom2 ? ticket.priceLabelCustom2 : '-'
    } / ${ticket.isInUseCustom3 ? ticket.priceLabelCustom3 : '-'}`;
    return label;
  }

  /**
   * checkDisplayInputMultiZoneCompatible
   * @returns
   */
  checkDisplayInputMultiZoneCompatible(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (this.titleFormData == this.titleEditReservation || this.titleFormData == this.titleAddReservation) {
      return false;
    }
    if (
      this.titleFormData == this.titleNewRegistrationSetTicket ||
      this.titleFormData == this.titleEditSetTicket ||
      this.titleFormData == this.titleDuplicateSetTicket
    ) {
      return true;
    }
  }

  /**
   * checkSalesLimitStatus
   */
  checkSalesLimitStatus(): void {
    if (!this.formSaleTicket.value?.salesLimitStatus) {
      this.formSaleTicket.get('maxParticipantCount').setValidators([Validators.required, Validators.min(1), Validators.max(99999)]);
      this.formSaleTicket.get('maxParticipantCount').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    } else {
      this.formSaleTicket.get('maxParticipantCount').clearValidators();
      this.formSaleTicket.get('maxParticipantCount').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * checkChangeDisplayUseVoucherCode
   */
  checkChangeDisplayUseVoucherCode(): void {
    if (this.formSaleTicket.value?.voucherType == Constant.FIXED_FIRST) {
      this.formSaleTicket.get('fixedVoucherCode').setValidators([Validators.required, this.eightCharactersValidator()]);
      this.formSaleTicket.get('fixedVoucherCode').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    } else {
      this.formSaleTicket.get('fixedVoucherCode').clearValidators();
      this.formSaleTicket.get('fixedVoucherCode').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * autoGenerateFixedVoucherCode
   */
  autoGenerateFixedVoucherCode(): void {
    const voucherCode = this.generateRandomCode();
    const patchObj = {
      fixedVoucherCode: voucherCode
    };
    this.formSaleTicket.patchValue(patchObj);
  }

  /**
   * generateRandomCode
   * @returns
   */
  generateRandomCode(): string {
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // requirement không bao gồm 0, O, 1, I
    let code = '';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }

    return code;
  }

  /**
   * eightCharactersValidator
   * @returns
   */
  eightCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      return value.length === 8 ? null : { invalidLength: true };
    };
  }

  /**
   * getAppSelected
   * @returns
   */
  getAppSelected(): ApplicationDTO | null {
    if (!this.listApp || !this.ticketSetDetail || !this.formSaleTicket) {
      return null;
    }
    let indexApp;
    if (!this.checkDisplayInputMultiZoneCompatible()) {
      indexApp = this.listApp?.findIndex(e => e.appId == this.setTicketSelected?.appId);
    } else {
      indexApp = this.listApp.findIndex(e => e.appId == this.formSaleTicket?.value?.appId);
    }
    if (indexApp == -1) {
      return null;
    }
    return this.listApp[indexApp];
  }

  /**
   * restrictToUpperCaseAndNumbers
   * @param event
   * @param formControlName
   */
  restrictToUpperCaseAndNumbers(event: Event, formControlName: string): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    // Chỉ cho phép nhập số half-size (0-9) và chữ cái lớn (A-Z)
    let sanitizedValue = currentValue
      .replace(/[^\u0030-\u0039A-Za-z]/g, '') // Chỉ giữ lại số và chữ cái
      .toUpperCase(); // Chuyển chữ cái nhỏ thành chữ cái lớn

    // Lưu giá trị mới vào input và dataset
    input.value = sanitizedValue;
    const patchObj = {};
    patchObj[formControlName] = sanitizedValue;
    this.formSaleTicket.patchValue(patchObj);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * translation
   * @param formControlName
   * @param source_language_code
   */
  async translation(formControlName: string, source_language_code: string): Promise<void> {
    let result = await Helper.translation(
      this.announcementManagerService,
      this.translateService,
      this.dialogService,
      this.languageKey,
      source_language_code,
      this.getAppSelected()?.supportedLanguage,
      this.formSaleTicket?.value[`${formControlName}${source_language_code}`]
    );
    if (!result) {
      return;
    }
    let updatedResult = {};
    const controlsToEmit = [];

    Object.keys(result).forEach(lang => {
      updatedResult[`${formControlName}${lang}`] = result[lang];
      controlsToEmit.push(`${formControlName}${lang}`);
    });

    this.formSaleTicket.patchValue(updatedResult);
    Object.keys(result).forEach(lang => {
      const controlName = `${formControlName}${lang}`;
      const control = this.formSaleTicket.get(controlName);

      if (control) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      }
    });

    this.bubblesInput(controlsToEmit);
  }

  /**
   * bubblesInput
   * @param controlsToEmit
   */
  bubblesInput(controlsToEmit: string[]) {
    this.formInputs.forEach(input => {
      const controlName = input.nativeElement.getAttribute('ng-reflect-name');
      if (controlsToEmit.includes(controlName)) {
        const event = new Event('input', { bubbles: true });
        this.renderer.selectRootElement(input.nativeElement).dispatchEvent(event);
      }
    });
  }

  /**
   * isEmptyObject
   * @param obj
   * @returns
   */
  isEmptyObjectScheduleUpdate(obj: any): boolean {
    return !obj || Object.keys(obj).length == 0 || Object.keys(obj).length == 1;
  }
}
